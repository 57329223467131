import { slideHide, slideShow } from '../utilities/animations';
import fetchJson from '../utilities/fetchJson';
import scrollTo from '../utilities/scroll-to';
import trackEvent from '../utilities/track-event';

export default class FrontendSubmit {
	constructor(el) {
		this.el = el;
		this.action = this.el.getAttribute('action');
		this.method = this.el.getAttribute('method') || 'post';

		this.campaignName =
			this.el.getAttribute('data-frontend-submit-campaign-name') || '';

		this.errorElement = this.el.querySelector('#form-error');
		this.confirmationElement = document.querySelector(
			this.el.getAttribute('data-frontend-submit-confirmation') || undefined
		);

		this.shouldHideForm =
			this.el.getAttribute('data-frontend-submit-hide-form') !== null;

		if (this.confirmationElement) {
			this.confirmationElement.style.display = 'none';
		}

		this.el.addEventListener('submit', this.handleSubmit);
	}

	handleSubmit = async (ev) => {
		ev.preventDefault();

		this.errorElement.style.display = 'none';
		this.errorElement.textContent = '';

		const formData = new FormData(this.el);
		const fields = {};
		formData.forEach(function (value, key) {
			fields[key] = value;
		});

		this.lock();

		try {
			const res = await fetchJson(this.action, {
				method: this.method,
				body: JSON.stringify(fields),
			});

			if (res.status === 'error') {
				this.errorElement.textContent =
					res.errorMessage ||
					'Någonting gick fel när formuläret skulle skickas. Vänligen försök igen.';
				slideShow(this.errorElement);
				this.unlock();
				return;
			}

			this.setSubmitLabel('Tack!');

			trackEvent(this.campaignName, 'Submitted');

			// Navigate to confirmation page if specified in the response
			if (res.confirmationPage) {
				window.location.href = res.confirmationPage;
			}

			// Show confirmation element if specified in the form attributes
			if (this.confirmationElement) {
				if (this.shouldHideForm) {
					slideHide(this.el);
				}
				slideShow(this.confirmationElement, () => {
					scrollTo(this.confirmationElement, -200);
				});
			}
		} catch (e) {
			console.warn(e);
			this.errorElement.textContent = e.message;
			slideShow(this.errorElement);

			this.unlock();
		}
	};

	lock = () => {
		this.el.setAttribute('disabled', 'disabled');
		this.el
			.querySelectorAll('input, textarea, button')
			.forEach((el) => el.setAttribute('disabled', 'disabled'));

		this.setSubmitLabel('Skickar…');
	};

	unlock = () => {
		this.el.removeAttribute('disabled');
		this.el
			.querySelectorAll('input, textarea, button')
			.forEach((el) => el.removeAttribute('disabled'));

		this.setSubmitLabel(undefined);
	};

	setSubmitLabel = (label) => {
		const submit = this.el.querySelector('[type="submit"]');
		if (submit) {
			if (this.originalSubmitLabel === undefined) {
				this.originalSubmitLabel = submit.value;
			}

			if (label === undefined && this.originalSubmitLabel !== undefined) {
				submit.value = this.originalSubmitLabel;
			} else {
				submit.value = label;
			}
		}
	};
}
