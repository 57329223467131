import { h, render } from 'preact';
import ProductPreview from './product-preview';
import { settingsSchema } from './schema';

export default function productPreview(el: HTMLElement) {
	if (!el) return;

	const settings = JSON.parse(el.getAttribute('data-product-preview') ?? '[]');
	if (!settings) return;

	const editable = el.getAttribute('data-product-preview-editable') !== 'false';

	settingsSchema.parse(settings);

	render(<ProductPreview settings={settings} editable={editable} />, el);
}
