/**
 * Handle the campaign header navigation menu
 */

import getFocusable from '../utilities/get-focusable';

export default class CampaignNavigation {
	el: HTMLElement;
	navigation: HTMLElement | null;
	menuButtonEl: HTMLElement | null;
	navbar: HTMLElement | null;
	openClassName = 'campaign-navbar--open';
	isOpen = false;

	constructor(el: HTMLElement) {
		this.el = el;
		this.navbar = this.el.querySelector('[data-campaign-navbar]');
		this.navigation = this.el.querySelector('[data-campaign-navigation]');
		this.menuButtonEl = this.el.querySelector('[data-campaign-hamburger]');
		if (!this.el || !this.navigation || !this.menuButtonEl || !this.navbar)
			return;
		this.menuButtonEl.addEventListener('click', this.handleButtonClick);
		window.addEventListener('keydown', this.handleKeyDown);
		document.addEventListener('click', this.handleClick);
		window
			.matchMedia('(min-width: 768px)')
			.addEventListener('change', this.handleResize);
	}

	open = () => {
		this.el.classList.add(this.openClassName);
		this.isOpen = true;
		this.navigation?.setAttribute('aria-expanded', 'true');
		this.menuButtonEl?.setAttribute('aria-label', 'Stäng meny');
	};

	close = () => {
		this.isOpen = false;
		this.el.classList.remove(this.openClassName);
		this.navigation?.setAttribute('aria-expanded', 'false');
		this.menuButtonEl?.setAttribute('aria-label', 'Öppna meny');
	};

	toggle = () => {
		if (this.isOpen) {
			this.close();
		} else if (!this.isOpen) {
			this.open();
		}
	};

	handleButtonClick = () => {
		this.toggle();
		this.menuButtonEl?.focus();
	};

	handleKeyDown = (ev: KeyboardEvent) => {
		const target = ev.currentTarget;

		if (!this.navbar) return;

		if (ev.key === 'Tab') {
			const [first, last] = getFocusable(this.navbar);

			if (document.activeElement === first && ev.shiftKey) {
				ev.preventDefault();
				last.focus();
				return;
			}
			if (document.activeElement === last && !ev.shiftKey) {
				ev.preventDefault();
				first.focus();
				return;
			}
		}

		if (ev.key === 'Escape') {
			this.close();
		}
	};

	handleClick = (event: MouseEvent) => {
		if (!this.navbar || event.target === this.menuButtonEl) return;

		if (
			(this.isOpen && event.target instanceof HTMLAnchorElement) ||
			(event.target instanceof Element && !this.navbar.contains(event.target))
		) {
			this.close();
		}
	};

	handleResize = (event: MediaQueryListEvent) => {
		if (event.matches) {
			this.close();
		}
	};
}
