export default class SubmitLock {
	el: HTMLFormElement;
	constructor(el: HTMLFormElement, attachToSubmit = true) {
		this.el = el;

		if (this.el.nodeName !== 'FORM') {
			throw new Error('SubmitLock can only be used on form elements.');
		}

		// The locked variable is added to the DOM node, so multiple SubmitLock
		// objects can control the behavior of the same node.
		this.el.__locked = this.el.__locked || false;

		if (attachToSubmit) {
			this.el.addEventListener('submit', this.handleSubmit);
		}
	}

	handleSubmit = (ev: SubmitEvent) => {
		if (this.el.__locked) {
			ev.preventDefault();
			return false;
		}
		this.lock();
	};

	// Query submit button here to work even if the DOM was changed since the object was initialized
	getSubmitEl = () => {
		return this.el.querySelector<HTMLInputElement | HTMLButtonElement>(
			'input[type="submit"], button[type="submit"]'
		);
	};

	lock = () => {
		const submitEl = this.getSubmitEl();
		if (this.el.__locked || !submitEl) {
			return;
		}

		this.el.__locked = true;

		if (!submitEl.getAttribute('__originalValue')) {
			submitEl.setAttribute('__originalValue', submitEl.value);
		}
		submitEl.value = 'Skickar…';
	};

	unlock = () => {
		const submitEl = this.getSubmitEl();

		if (!this.el.__locked || !submitEl) {
			return;
		}

		this.el.__locked = false;
		const originalValue = submitEl.getAttribute('__originalValue');
		if (originalValue) {
			submitEl.value = originalValue;
			submitEl.removeAttribute('__originalValue');
		}
	};
}
