export default class Quantity {
	quantityActionAttribute = 'data-quantity-action';

	constructor(el) {
		this.el = el;
		this.field = this.el.querySelector('input');

		this.el
			.querySelectorAll(`[${this.quantityActionAttribute}]`)
			.forEach(actionEl =>
				actionEl.addEventListener('click', this.handleClick)
			);
	}

	handleClick = ev => {
		ev.preventDefault();
		const action = ev.target.getAttribute(this.quantityActionAttribute);
		switch (action) {
			case 'increase':
				this.increase();
				break;
			case 'decrease':
				this.decrease();
				break;
		}
	};

	increase = () => {
		this.field.stepUp(1);
	};

	decrease = () => {
		this.field.stepDown(1);
	};
}
