import { useState, useEffect } from 'preact/hooks';

export function useMediaQuery(query: string) {
	const [matches, setMatches] = useState(false);
	useEffect(() => {
		let mounted = true;

		const media = window.matchMedia(query);
		if (media.matches !== matches) {
			setMatches(media.matches);
		}

		const listener = () => {
			if (!mounted) return;
			setMatches(media.matches);
		};

		media.addEventListener('change', listener);
		return () => {
			removeEventListener('change', listener);
			mounted = false;
		};
	}, [matches, query]);

	return matches;
}
