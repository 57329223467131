import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import getFocusable from '../utilities/get-focusable';

document.addEventListener('DOMContentLoaded', function () {
	const popups = document.querySelectorAll<HTMLElement>('[data-popup]');

	const stage = document.createElement('div');
	document.body.appendChild(stage);

	if (popups.length === 0) return;

	popups.forEach((button) => {
		const selector = button.getAttribute('data-popup');
		if (!selector) return;
		const template = document.querySelector(selector);
		if (!template) return;

		let isOpen = false;

		const popupEl = document.createElement('div');
		popupEl.classList.add('popover', 'popover--wide', 'popover--products');

		let contentEl: HTMLElement;

		const createPopupElements = (content: DocumentFragment) => {
			const button = document.createElement('button');
			button.setAttribute('class', 'popover__close');
			button.setAttribute('data-popup-close', '');
			button.innerHTML = `Stäng`;

			popupEl.appendChild(button);

			if (!contentEl) {
				contentEl = document.createElement('div');
				contentEl.classList.add('popover__box');
				contentEl.appendChild(content);
			}
			popupEl.appendChild(contentEl);

			stage.appendChild(popupEl);
			stage.style.display = 'flex';
			disableBodyScroll(contentEl);
		};

		const open = () => {
			if (isOpen) return;
			if (template && template instanceof HTMLTemplateElement) {
				const content = template.content.cloneNode(true) as DocumentFragment;
				createPopupElements(content);
				popupEl.classList.add('popover--open');
				isOpen = true;
				moveFocus();
			}
		};

		const close = () => {
			if (!isOpen) return;
			stage.style.display = 'none';
			enableBodyScroll(contentEl);
			while (popupEl.firstChild) {
				popupEl.removeChild(popupEl.firstChild);
			}
			popupEl.parentNode?.removeChild(popupEl);
			popupEl.classList.remove('popover--open');
			isOpen = false;

			moveFocus();
		};

		const moveFocus = () => {
			if (isOpen) {
				const [firstFocusable] = getFocusable(popupEl);
				firstFocusable?.focus();
			} else {
				button.focus();
			}
		};

		button.addEventListener('click', () => {
			if (isOpen) {
				close();
			} else {
				open();
			}
		});

		stage.addEventListener('click', (event) => {
			if (!(event.target instanceof Element)) return;

			const clickOnClose = event.target.hasAttribute('data-popup-close');
			const clickOnOverlay = event.target === popupEl;

			if (clickOnClose || clickOnOverlay) {
				close();
			}
		});

		/**
		 * Add behaviors for when the user presses a key
		 */
		window.addEventListener('keydown', (event: KeyboardEvent) => {
			if (!isOpen) return;

			// Close when escape is pressed
			if (
				event.key === 'Escape' &&
				event.target instanceof Element &&
				popupEl.contains(event.target)
			) {
				close();
			}

			// Lock focus within
			if (event.key === 'Tab') {
				const [firstFocusableEl, lastFocusableEl] = getFocusable(popupEl);
				if (event.shiftKey) {
					if (document.activeElement === firstFocusableEl) {
						lastFocusableEl?.focus();
						event.preventDefault();
					}
				} else {
					if (document.activeElement === lastFocusableEl) {
						firstFocusableEl?.focus();
						event.preventDefault();
					}
				}
			}
		});

		document.addEventListener('click', (event: MouseEvent) => {
			if (
				event.target === button ||
				(event.target instanceof Element && button.contains(event.target))
			)
				return;
			if (
				isOpen &&
				event.target instanceof Element &&
				!popupEl.contains(event.target)
			) {
				close();
			}
		});
	});
});
