export default function getFocusable<T extends Element>(
	el: T
): [T | null, T | null] {
	const focusable = el.querySelectorAll<T>(
		'a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
	);
	const first = focusable[0];
	const last = focusable[focusable.length - 1];

	return [first, last];
}
