import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import { bmpFilter, dingbatFilter } from '../utilities/strings';
const replacementAttr = 'data-replacement';
const imagePreviewAttr = 'data-image-preview';
const maxLinebreakAttr = 'data-max-linebreaks';

/**
 * Handle the gift card preview
 */

export default class GiftCardForm {
	isLarge = false;

	constructor(el) {
		this.el = el;
		this.previewEls = this.el.querySelectorAll(
			'iframe[data-gift-card-preview]'
		);
		this.wrappers = [...this.previewEls].map(
			(previewEl) => previewEl.parentNode
		);
		this.enlargeButton = this.el.querySelector('[data-show-large]');

		/**
		 * Wait to initialize until window load event when the iframes have loaded
		 */
		window.addEventListener('load', this.initialize);

		document.addEventListener('keydown', this.handleDocumentKeyDown);
		document.addEventListener('keyup', this.handleDocumentKeyUp);
		document.addEventListener('click', this.handleDocumentClick);
		window.addEventListener('resize', this.handleResize);
		window.addEventListener('load', this.handleResize);
		window.addEventListener('step-activate', this.setBoundingRects);
		window.addEventListener(
			'radio-set-controls-visible',
			this.setBoundingRects
		);

		this.handleResize();
		this.updateConditionalDots();

		if (this.enlargeButton) {
			this.enlargeButtonOpenText = this.enlargeButton.textContent;
			this.enlargeButtonCloseText =
				this.enlargeButton.getAttribute('data-label-close');
			this.enlargeButton.addEventListener('click', this.handleToggleLarge);
		}
	}

	initialize = () => {
		this.previewEls.forEach((previewEl) => {
			previewEl.contentDocument.documentElement.classList.add('preview-mode');
			previewEl.contentDocument.documentElement.style.overflow = 'hidden';
			previewEl.removeAttribute('data-hide-before-load');
		});

		this.setBoundingRects();

		const inputs = this.el.querySelectorAll(`[${replacementAttr}]`);
		this.inputs = inputs
			? [...inputs].map((input) => new GiftCardInput(input, this.previewEls))
			: [];

		const fileInputs = this.el.querySelectorAll(`[${imagePreviewAttr}]`);
		this.fileInputs = fileInputs
			? [...fileInputs].map(
					(input) => new GiftCardImageInput(input, this.previewEls)
			  )
			: [];

		this.conditionalDots = [...this.previewEls].flatMap((previewEl) => [
			...previewEl.contentDocument.documentElement.querySelectorAll(
				'[data-conditional-dots]'
			),
		]);

		this.handleResize();
	};

	openPreview = () => {
		if (this.isLarge) return;

		const previewEl = this.el.querySelector('.card-preview');
		if (!previewEl) return;

		disableBodyScroll(this.el);
		previewEl.classList.add('card-preview--large');
		this.isLarge = true;
		this.enlargeButton.textContent = this.enlargeButtonCloseText;

		this.handleResize();
	};

	closePreview = () => {
		if (!this.isLarge) return;

		const previewEl = this.el.querySelector('.card-preview');
		if (!previewEl) return;

		enableBodyScroll(this.el);
		previewEl.classList.remove('card-preview--large');
		this.isLarge = false;
		this.enlargeButton.textContent = this.enlargeButtonOpenText;

		this.handleResize();
	};

	setBoundingRects = () => {
		this.previewElRects = [...this.previewEls].map((previewEl) => {
			previewEl.style.removeProperty('transform');
			const rect = previewEl.getBoundingClientRect();
			return rect;
		});

		this.handleResize();
	};

	handleResize = () => {
		if (!this.previewElRects) return;

		this.previewEls.forEach((previewEl, i) => {
			const previewElRect = this.previewElRects[i];
			const wrapperRect = this.wrappers[i].getBoundingClientRect();
			const factor = wrapperRect.width / previewElRect.width;
			if (factor) {
				previewEl.style.transform = `scale(${factor})`;
			}
		});
	};

	handleToggleLarge = (ev) => {
		ev.preventDefault();

		if (this.isLarge) {
			this.closePreview();
		} else {
			this.openPreview();
		}
	};

	handleDocumentClick = (ev) => {
		const clickedPreview = ev.target.classList.contains('card-preview');
		if (this.isLarge && clickedPreview) {
			this.closePreview();
		}
	};

	handleDocumentKeyDown = (ev) => {
		const pressedEscape = ev.key === 'Escape';
		if (this.isLarge && pressedEscape) {
			this.closePreview();
		}
	};

	handleDocumentKeyUp = () => {
		this.updateConditionalDots();
	};

	updateConditionalDots = () => {
		if (!this.conditionalDots || this.conditionalDots.length === 0) return;

		const hasNameAndGreeting = this.inputs
			.filter((input) => {
				return input.el.id === 'name' || input.el.id === 'greeting';
			})
			.some((input) => input.el.value.trim().length > 0);

		this.conditionalDots.forEach((dotEl) => {
			dotEl.style.display = hasNameAndGreeting ? 'block' : 'none';
		});
	};
}

class GiftCardInput {
	constructor(el, previewEls) {
		this.el = el;
		const replacement = this.el.getAttribute(replacementAttr);
		this.targets = [...previewEls]
			.map((previewEl) => previewEl.contentDocument.querySelector(replacement))
			.filter((target) => !!target);

		if (!this.targets || this.targets.length === 0) return;

		this.originalValue = this.getTargetValue();
		this.maxLinebreaks = this.el.getAttribute(maxLinebreakAttr);

		if (this.maxLinebreaks) {
			this.maxLinebreaks = Number(this.maxLinebreaks);
			if (isNaN(this.maxLinebreaks)) {
				this.maxLinebreaks = false;
			}
		}

		if (this.maxLinebreaks) {
			this.el.addEventListener('keydown', this.handleKeyDown);
		}
		this.el.addEventListener('click', this.handleKeyUp);
		this.el.addEventListener('keyup', this.handleKeyUp);

		if (this.el.type !== 'radio' || this.el.checked) {
			this.setTargetValue(this.getInputValue());
		}
	}

	handleKeyDown = (ev) => {
		if (!this.maxLinebreaks) return true;

		const linebreaks = (this.el.value.match(/\n/g) || '').length + 1;
		const endsInDoubleLinebreak = this.el.value.match(/\n{2,}$/, '');
		if (
			ev.key === 'Enter' &&
			(linebreaks > this.maxLinebreaks || endsInDoubleLinebreak)
		) {
			ev.preventDefault();
		}
		return true;
	};

	handleKeyUp = () => {
		this.setTargetValue(this.getInputValue());
	};

	getInputValue = () => {
		if (this.el.type === 'radio') {
			return this.el.getAttribute('data-image-url');
		}

		return bmpFilter(dingbatFilter(this.el.value));
	};

	setTargetValue = (value) => {
		this.targets.forEach((target) => {
			target[target.tagName === 'IMG' ? 'src' : 'innerText'] =
				value || this.originalValue;
		});
	};

	getTargetValue = () => {
		const target = this.targets[0]; // assuming that both targets have the same value
		if (target.tagName === 'IMG') {
			return target.src;
		}
		return target.innerText;
	};
}

class GiftCardImageInput {
	constructor(el, previewEls) {
		this.el = el;

		this.targets = [...previewEls]
			.map((previewEl) =>
				previewEl.contentDocument.querySelector(
					this.el.getAttribute(imagePreviewAttr)
				)
			)
			.filter((target) => !!target);

		if (!this.targets || this.targets.length === 0) {
			console.warn(
				'GiftCardImageInput target not found',
				this.el.getAttribute(imagePreviewAttr)
			);
		}

		this.targets.forEach((target) => (target.style.display = 'none'));

		this.reader = new FileReader();
		this.reader.addEventListener('load', this.handleReaderLoad);

		this.el.addEventListener('change', this.handleChange);
	}

	handleChange = () => {
		const file = this.el.files[0] || null;

		// Timeout to make sure the `validity.valid` refers to the new file
		setTimeout(() => {
			if (file && this.el.validity.valid) {
				this.reader.readAsDataURL(file);
				this.targets.forEach((target) => (target.style.display = 'block'));
			} else {
				this.targets.forEach((target) => {
					target.src = '';
					target.style.display = 'none';
				});
			}
		}, 1);
	};

	handleReaderLoad = () => {
		this.targets.forEach((target) => {
			target.src = this.reader.result;
		});
	};
}
