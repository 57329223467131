import { h, FunctionalComponent as FC, JSX, Fragment } from 'preact';
import { useCallback, useEffect, useRef, useState } from 'preact/hooks';
import { Field } from './types';
import Input from '../input';
import ImageList from './image-list';
import ExampleList from './example-list';
import getFieldValue from './utilities/field-value';
import {
	containsCharsOutsideBmp,
	containsDingbats,
} from '../../utilities/strings';

type EditProps = {
	fields: Field[];
	fieldValues: Record<string, string>;
	strings: Record<string, string>;
	autoFocus?: boolean;
	onUpdate: (fieldValues: Record<string, string>) => void;
	onClose: () => void;
};

type ValidationMessages = Record<string, string | null>;

const errorMessages: Record<string, string> = {
	valueMissing: 'Vänligen fyll i fältet.',
	emojiDetected: 'Det går tyvärr inte att använda emojis på tryckta gåvobevis.',
};

const Edit: FC<EditProps> = ({
	fields,
	fieldValues,
	strings,
	autoFocus,
	onUpdate,
	onClose,
}) => {
	const [values, setValues] = useState<Record<string, string>>(fieldValues);
	const [validationMessages, setValidationMessages] =
		useState<ValidationMessages>({});
	const textarea = useRef<HTMLTextAreaElement>(null);
	const [showValidation, setShowValidation] = useState(false);

	useEffect(() => {
		const handleKeyDown = (ev: KeyboardEvent) => {
			if (ev.key === 'Escape') {
				onClose();
			}
		};

		document.addEventListener('keydown', handleKeyDown);
		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [onClose]);

	const handleInput = useCallback(
		(inputValue: string, name?: string) => {
			if (!name) return;

			setValues((prev) => {
				return { ...prev, ...{ [name]: inputValue } };
			});

			setValidationMessages((prev) => ({ ...prev, ...{ [name]: null } }));

			const field = fields.find((field) => field.id === name);
			if (field && field.type === 'image' && field.presentation === 'inline') {
				onUpdate({ [name]: inputValue });
			}
		},
		[fields, values, onUpdate]
	);

	const handleAccept = () => {
		let isValid = true;
		const validationMessages: ValidationMessages = {};

		fields.forEach((field) => {
			if ('required' in field && field.required && !values[field.id]) {
				isValid = false;
				validationMessages[field.id] = errorMessages.valueMissing;
			}

			if ('validateNoEmoji' in field && field.validateNoEmoji) {
				if (
					containsCharsOutsideBmp(values[field.id]) ||
					containsDingbats(values[field.id])
				) {
					isValid = false;
					validationMessages[field.id] = errorMessages.emojiDetected;
				}
			}
		});

		if (!isValid) {
			setShowValidation(true);
			setValidationMessages(validationMessages);
		} else {
			onUpdate(values);
			onClose();
		}
	};

	const setExampleValue = (value: string) => {
		const prevFocusedElement = document.activeElement;
		textarea.current?.focus();
		textarea.current?.select();
		document.execCommand('insertText', false, value);
		if (prevFocusedElement instanceof HTMLElement) {
			prevFocusedElement?.focus();
		}
	};

	const handleKeyDown = (
		event: JSX.TargetedKeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		const isInput = event.target instanceof HTMLInputElement;
		const isTextArea = event.target instanceof HTMLTextAreaElement;
		if (
			(isInput && event.key === 'Enter') ||
			(isTextArea && event.key === 'Enter' && event.metaKey)
		) {
			event.preventDefault();
			handleAccept();
		}
	};

	const displayInline = fields.some(
		(field) => field.type === 'image' && field.presentation === 'inline'
	);

	if (fields.length === 0) return null;

	return (
		<div
			style={{
				...blockStyle,
				marginBottom: displayInline ? '-5%' : undefined,
			}}
		>
			{fields.every((field) => field.area === 'name') && (
				<span className="heading heading--no-underline">
					{strings.nameLabel}
				</span>
			)}
			{fields.map((field, i) => {
				const value = getFieldValue(field, values);
				return (
					<Fragment key={field.id}>
						{field.type === 'text' && (
							<Input
								key={field.id}
								name={field.id}
								value={value}
								label={field.name}
								placeholder={field.placeholder}
								as="input"
								maxLength={field.maxLength}
								onInput={handleInput}
								onKeyDown={handleKeyDown}
								autoFocus={autoFocus && i === 0}
								style={inputStyle}
								required={field.required}
								showValidation={showValidation}
								validationMessage={validationMessages[field.id]}
								autocomplete={field.autocomplete}
							/>
						)}
						{field.type === 'textarea' && (
							<Input
								key={field.id}
								name={field.id}
								value={value}
								label={field.name}
								placeholder={field.placeholder}
								as="textarea"
								maxLength={field.maxLength}
								maxLineBreaks={field.maxLineBreaks}
								rows={field.rows}
								fieldRef={textarea}
								onInput={handleInput}
								onKeyDown={handleKeyDown}
								autoFocus={autoFocus && i === 0}
								style={inputStyle}
								required={field.required}
								showValidation={showValidation}
								validationMessage={validationMessages[field.id]}
							/>
						)}
						{field.type === 'image' &&
							field.images &&
							field.images?.length > 0 && (
								<ImageList
									name={field.id}
									images={field.images}
									selected={value}
									setSelected={handleInput}
								/>
							)}
						{field.type !== 'image' &&
							field.exampleValues &&
							field.exampleValues.length > 0 && (
								<ExampleList
									examples={field.exampleValues}
									selected={value}
									setSelected={setExampleValue}
								/>
							)}
					</Fragment>
				);
			})}

			{!displayInline && (
				<div className="button-set">
					<button
						onClick={handleAccept}
						className="button-set__button button button--filled"
						type="button"
					>
						OK
					</button>
					<button
						onClick={onClose}
						className="button-set__button button"
						type="button"
					>
						Avbryt
					</button>
				</div>
			)}
		</div>
	);
};

export default Edit;

const blockStyle: JSX.CSSProperties = {
	display: 'flex',
	flexDirection: 'column',
	gap: '0.5rem',
	maxWidth: 'calc(100% - var(--page-margin))',
	width: '28rem',
	margin: '0 auto',
};

const inputStyle: JSX.CSSProperties = {
	width: '100%',
};
