import { h, FunctionalComponent as FC, JSX } from 'preact';
import styles from './edit-symbol.module.css';

type EditSymbolProps = {
	style?: JSX.CSSProperties;
	className?: string;
};

const EditSymbol: FC<EditSymbolProps> = ({ style, className = '' }) => {
	return (
		<div style={style} className={[className, styles.wrap].join(' ')}>
			<svg
				width="32"
				height="32"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 32 32"
				className={styles.icon}
			>
				<path
					d="M20.612 8.982a2.39 2.39 0 00-1.695.71l-9.225 9.224-.036.183-.638 3.209-.182.856.857-.182 3.208-.638.182-.037 9.225-9.224a2.379 2.379 0 000-3.39 2.39 2.39 0 00-1.696-.711zm0 1.112c.294 0 .59.134.875.419.567.567.567 1.182 0 1.75l-.42.4-1.732-1.731.402-.419c.285-.285.58-.42.875-.42zm-2.096 1.659l1.731 1.732-7.054 7.053a3.976 3.976 0 00-1.733-1.731l7.056-7.054zm-7.802 8.002a2.76 2.76 0 011.53 1.532l-1.914.382.384-1.914z"
					fill="#fff"
				/>
			</svg>
		</div>
	);
};

export default EditSymbol;
