export default class ShareLink {
	constructor(el) {
		this.el = el;
		this.el.addEventListener('click', this.handleShareClick);
	}

	handleShareClick = ev => {
		ev.preventDefault();
		const url = this.el.getAttribute('href');
		openShareWindow(url);
	};
}

function openShareWindow(url) {
	if (!url) {
		return;
	}

	const height = 360;
	const width = 640;
	const settings = [
		'top=' + Math.floor(window.screen.height / 2 - height / 2),
		'left=' + Math.floor(window.screen.width / 2 - width / 2),
		'height=' + height,
		'width=' + width,
		'menubar=no',
		'location=no',
		'resizable=yes',
		'scrollbars=yes',
		'status=no',
	].join(',');

	window.open(url, '', settings);
}
