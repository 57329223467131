import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';
import getFocusable from '../utilities/get-focusable';

export default class Popover {
	isOpen = false;

	constructor(el) {
		this.el = el;

		this.id = this.el.getAttribute('data-popover');
		this.box = this.el.querySelector('.popover__box');

		const closeButtons = this.el.querySelectorAll('[data-popover-close]');
		if (closeButtons && closeButtons.length > 0) {
			closeButtons.forEach((closeButton) => {
				closeButton.addEventListener('click', () => {
					this.close();
				});
			});
		}

		/* Click background to close */
		let mouseDownTarget = null;
		this.el.addEventListener('mousedown', (ev) => {
			mouseDownTarget = ev.target;
		});
		this.el.addEventListener('click', (ev) => {
			if (ev.target === mouseDownTarget && ev.target === this.el) {
				this.close();
			}
		});

		/* Manage focus */
		let focusTimeoutId;
		window.addEventListener(
			'focus',
			(ev) => {
				if (!this.isOpen) return;

				window.clearTimeout(focusTimeoutId);
				focusTimeoutId = window.setTimeout(() => {
					if (
						ev.target instanceof HTMLElement &&
						this.el.contains(ev.target) === false
					) {
						const [first] = getFocusable(this.el);
						if (first) {
							first.focus();
						}
					}
				}, 1);
			},
			true
		);

		/* Handle escape */
		window.addEventListener('keydown', (ev) => {
			if (this.isOpen && ev.key === 'Escape') {
				this.close();
			}
		});
	}

	open(openedFrom) {
		this.el.classList.add('popover--open');
		this.isOpen = true;

		this.lastOpenedFrom = openedFrom;

		if (this.box) {
			this.box.scrollTop = 0;
			disableBodyScroll(this.box);
		}

		const [first] = getFocusable(this.el);
		first?.focus();
	}

	close() {
		this.el.classList.remove('popover--open');
		this.isOpen = false;
		if (this.box) {
			enableBodyScroll(this.box);
		}

		if (this.lastOpenedFrom) {
			this.lastOpenedFrom.focus();
			this.lastOpenedFrom = undefined;
		}
	}

	toggle(clickedElement) {
		if (this.isOpen) {
			this.close();
		} else {
			this.open(clickedElement);
		}
	}
}
