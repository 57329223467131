export default class RecommendMessage {
	constructor(el, target) {
		this.input = el;

		const recommendElementSelector = el.getAttribute('data-recommend-target');
		this.recommend = target || document.querySelector(recommendElementSelector);

		if (!this.input || !this.recommend) {
			console.warn('RecommendMessage: input or target not found');
			return;
		}

		this.recommendMessageTemplate = this.recommend.getAttribute(
			'data-recommend-message-template'
		);
		this.recommendOperationCost = Number(
			this.recommend.getAttribute('data-operation-cost')
		);
		this.recommendMessageLowTemplate = this.recommend.getAttribute(
			'data-recommend-message-low-template'
		);
		this.defaultRecommendMessage = this.recommend.innerHTML;

		this.input.addEventListener('keyup', this.handleKeyUp);
	}

	handleKeyUp = () => {
		const num = Number(this.input.value);
		const operations = Math.floor(num / this.recommendOperationCost);

		if (isNaN(num) || !this.input.validity.valid) {
			this.recommend.innerHTML = this.defaultRecommendMessage;
		} else if (num && operations > 0 && this.recommendMessageTemplate) {
			this.recommend.innerHTML = this.recommendMessageTemplate.replace(
				'%n',
				`<em>${operations}</em>`
			);
		} else if (this.recommendMessageLowTemplate) {
			this.recommend.innerHTML = this.recommendMessageLowTemplate;
		}
	};
}
