import anime from 'animejs';
import { slideShow } from '../utilities/animations';
import fetchJson from '../utilities/fetchJson';
import { validatePersonalNumber } from '../utilities/personal-number';
import delay from '../utilities/delay';

export default class PersonalNumber {
	previouslyFilled = false;
	previouslySubmittedValue = '';
	isLoading = false;

	constructor(el) {
		this.el = el;

		this.endpoint = this.el.getAttribute('data-endpoint');
		this.nonce = this.el.getAttribute('data-nonce');
		this.input = this.el.querySelector('[data-pn-input]');
		this.button = this.el.querySelector('[data-pn-button]');
		this.loading = this.el.querySelector('[data-pn-loading]');
		this.results = this.el.querySelector('[data-pn-results]');
		this.error = this.el.querySelector('[data-pn-error]');
		this.loading.style.display = 'none';
		this.results.style.display = 'none';
		this.error.style.display = 'none';

		this.loadingFadeIn = anime({
			targets: this.loading,
			duration: 300,
			opacity: [0, 1],
			autoplay: false,
		});

		this.loadingFadeOut = anime({
			targets: this.loading,
			duration: 300,
			opacity: [1, 0],
			autoplay: false,
		});

		this.button.addEventListener('click', (ev) => {
			ev.preventDefault();
			this.handleSubmit();
		});
		this.input.addEventListener('change', () => {
			this.button.removeAttribute('disabled');
		});

		this.input.addEventListener('blur', () => {
			this.handleSubmit();
		});
	}

	handleSubmit = async () => {
		if (
			!this.input.validity.valid ||
			this.isLoading === true ||
			this.input.value.trim() === ''
		) {
			return;
		}

		// Prevent submission if it's the same as the previously submitted
		if (this.previouslySubmittedValue === this.input.value) {
			return;
		}
		this.previouslySubmittedValue = this.input.value;

		this.button.classList.remove('button--filled');

		this.setError(false);
		this.isLoading = true;
		this.setLoading(true);

		try {
			const info = await this.getInfo(this.input.value);
			this.populateFields(info);
			this.button.setAttribute('disabled', 'disabled');

			if (!this.previouslyFilled) {
				await delay(200); // delay to not show the transition when inputs are populated
				slideShow(this.results);
				this.previouslyFilled = true;
			}
		} catch (e) {
			this.setError(e.message);
		}

		this.setLoading(false);
		this.isLoading = false;
	};

	getInfo = async (personalNumber) => {
		personalNumber = personalNumber.replace(/[^0-9-]/g, '');

		const validPersonalNumber = validatePersonalNumber(personalNumber);
		if (!validPersonalNumber) {
			throw new Error(
				'Det verkar som du råkat skriva ett felaktigt personnummer.'
			);
		}

		const query = [
			`number=${encodeURIComponent(personalNumber)}`,
			`_wpnonce=${encodeURIComponent(this.nonce)}`,
		].join('&');

		const qsJoinMark = this.endpoint.indexOf('?') === -1 ? '?' : '&';
		const url = [this.endpoint, query].join(qsJoinMark);
		const data = await fetchJson(url);

		if (data.status === 'error') {
			throw new Error(`${data.errorMessage}`);
		}

		return data.payload;
	};

	populateFields = (info) => {
		let keyupEvent;
		let changeEvent;

		if (typeof Event === 'function') {
			keyupEvent = new Event('keyup');
			changeEvent = new Event('change');
		} else {
			keyupEvent = document.createEvent('Event');
			keyupEvent.initEvent('keyup', true, true);
			changeEvent = document.createEvent('Event');
			changeEvent.initEvent('change', true, true);
		}

		for (const key in info) {
			if (info.hasOwnProperty(key)) {
				const field = this.results.querySelector(`[name="${key}"]`);
				if (field) {
					field.value = info[key];
					field.dispatchEvent(keyupEvent); // for correct label position
					field.dispatchEvent(changeEvent); // to activate validation
					field.removeAttribute('disabled');
					field.setAttribute('readonly', 'readonly');
				}
			}
		}

		// Fire a custom event, that the BankID form can listen for to pre-fill the
		// personal number in that form.
		const personalNumberFilledEvent = new CustomEvent(
			'personal-number-filled',
			{
				detail: this.input.value,
			}
		);
		window.dispatchEvent(personalNumberFilledEvent);
	};

	setError = (message) => {
		if (message) {
			this.error.textContent = message;
			this.error.classList.add('validation--error');
			this.input.setCustomValidity(message);
			slideShow(this.error);
		} else {
			this.error.textContent = '';
			this.error.classList.remove('validation--error');
			this.error.style.display = '';
			this.input.setCustomValidity('');
		}
	};

	setLoading = (on) => {
		this.loading.style.display = '';
		if (on) {
			this.loadingFadeIn.play();
		} else {
			this.loadingFadeOut.play();
		}
	};
}
