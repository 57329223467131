import { slideShow, slideHide } from '../utilities/animations';
import {
	containsCharsOutsideBmp,
	containsDingbats,
} from '../utilities/strings';
import { filesAboveMaxSize, formatFileSize } from '../utilities/files';

type MismatchKey = `${string}Mismatch`;
type FieldErrorMessageKey = keyof ValidityState | MismatchKey;

/**
 * Extend browser form field validation
 */

export default class FieldValidation {
	el: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
	validationErrorEl?: HTMLElement;

	showValidationStatusClassName = 'u-show-validation';
	wasChanged = false;
	errorMessageIsDisplayed = false;

	errorMessages: Partial<Record<FieldErrorMessageKey, string>> = {
		valueMissing: 'Vänligen fyll i fältet.',
		emailMismatch: 'Ange en giltig e-postadress.',
		patternMismatch: 'Värdet är inte rätt formaterat.',
		rangeUnderflow: 'Värdet är för lågt.',
		rangeOverflow: 'Värdet är för högt.',
	};

	constructor(el: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement) {
		this.el = el;

		try {
			const validationSelector = this.el.getAttribute('data-validation-error');
			this.validationErrorEl = validationSelector
				? document.querySelector<HTMLElement>(validationSelector) || undefined
				: undefined;
		} catch (error) {
			console.warn(error);
		}

		this.el.addEventListener('blur', this.handleBlur);
		this.el.addEventListener('change', this.handleChange);
		this.el.addEventListener('invalid', this.handleInvalid);

		const messageKeyToAttr: [FieldErrorMessageKey, string][] = [
			['valueMissing', 'data-value-missing'],
			['badInput', 'data-bad-input'],
			['rangeUnderflow', 'data-range-underflow'],
			['rangeOverflow', 'data-range-overflow'],
		];

		messageKeyToAttr.forEach(([errorKey, attributeKey]) => {
			const errorMessage = this.el.getAttribute(attributeKey);
			if (errorMessage) {
				this.errorMessages[errorKey] = errorMessage;
			}
		});

		const patternMismatch = this.el.getAttribute('data-pattern-mismatch');
		if (patternMismatch) {
			this.errorMessages.patternMismatch = patternMismatch;
		}
	}

	handleBlur = () => {
		if (this.wasChanged) {
			this.el.classList.add(this.showValidationStatusClassName);
		}
	};

	handleChange = () => {
		this.wasChanged = true;
		this.el.classList.add(this.showValidationStatusClassName);

		const validateNoEmoji = this.el.getAttribute('data-validate-no-emoji');
		if (
			validateNoEmoji !== null &&
			(containsCharsOutsideBmp(this.el.value) ||
				containsDingbats(this.el.value))
		) {
			this.el.setCustomValidity(
				validateNoEmoji || 'Fältet får inte innehålla emoji.'
			);
		} else {
			this.el.setCustomValidity('');
		}

		if (this.el instanceof HTMLInputElement) {
			const maxFileSize = this.el.getAttribute('data-validate-max-filesize');
			if (
				maxFileSize !== null &&
				this.el.files &&
				filesAboveMaxSize(this.el.files, maxFileSize)
			) {
				this.el.setCustomValidity(
					`Den angivna filen är för stor (max ${formatFileSize(maxFileSize)})`
				);
			}
		}

		if (this.el.checkValidity() && this.validationErrorEl) {
			this.errorMessageIsDisplayed = false;
			slideHide(this.validationErrorEl, () => {
				this.validationErrorEl!.textContent = '';
			});
		}
	};

	handleInvalid = (ev: Event) => {
		this.el.classList.add(this.showValidationStatusClassName);

		if (!this.validationErrorEl) return;

		ev.preventDefault();
		const errorMessage = this.getErrorMessage() || '';
		if (errorMessage) {
			this.validationErrorEl.textContent = errorMessage;
		}

		if (!this.errorMessageIsDisplayed) {
			this.errorMessageIsDisplayed = true;
			this.validationErrorEl.classList.add('validation--error');
			slideShow(this.validationErrorEl);
		} else if (!errorMessage) {
			this.errorMessageIsDisplayed = false;
			slideHide(this.validationErrorEl, () => {
				this.validationErrorEl!.textContent = errorMessage;
			});
		}
	};

	getErrorMessage = () => {
		const { validity, type } = this.el;

		if (validity.valid) {
			return undefined;
		}

		if (validity.customError && this.el.validationMessage) {
			return this.el.validationMessage;
		}

		const mismatchKey: MismatchKey = `${type}Mismatch`;
		if (validity.typeMismatch && mismatchKey in this.errorMessages) {
			return this.errorMessages[mismatchKey];
		}

		for (const invalidKey in this.errorMessages) {
			/**
			 * Note: TypeScript does not figure this out itself, so casting is necessary.
			 * This cast type is correct because `${string}Mismatch` is handled above and
			 * the type should be narrowed to `ValidityState`.
			 */
			const property = invalidKey as keyof ValidityState;
			if (
				this.errorMessages.hasOwnProperty(property) &&
				isValidityStateKey(property) &&
				validity[property]
			) {
				return this.errorMessages[invalidKey];
			}
		}

		return undefined;
	};
}

const isValidityStateKey = (key: string): key is keyof ValidityState => {
	return key in ValidityState.prototype;
};
