import { h, render, Component } from 'preact';
import { useState } from 'preact/hooks';
import Input from './input';
import { pingForResponse, initiateRequest } from '../utilities/bankid';
import { validatePersonalNumber } from '../utilities/personal-number';
import detectDeviceType from '../utilities/device-detection';

const qrMode = 'qr';
const linkMode = 'link';

export default function BankIDAccounts(el) {
	let banks = [];
	try {
		banks = JSON.parse(el.getAttribute('data-banks'));
	} catch (e) {
		console.warn('Banks JSON string could not be parsed.');
	}

	const endpoint = el.getAttribute('data-endpoint');
	const nonce = el.getAttribute('data-nonce');

	if (el) {
		render(
			<BankIDComponent banks={banks} endpoint={endpoint} nonce={nonce} />,
			el
		);
	}
}

const StageInitial = Symbol('BankID Accounts Initial');
const StageContactingBank = Symbol('BankID Accounts Contacting Bank');
const StageWaitingForSignature = Symbol(
	'BankID Accounts Waiting For Signature'
);
const StageSelectAccount = Symbol('BankID Accounts Select Account');
const StageError = Symbol('BankID Accounts Error');

class BankIDComponent extends Component {
	state = {
		personalNumber: '',
		selectedBank: '',
		bankWasChanged: false,
		stage: StageInitial,
		accounts: [],
		selectedAccount: null,
		loading: false,
		errorMessage: '',
		qr: '',
		link: '',
	};

	componentDidMount() {
		window.addEventListener(
			'personal-number-filled',
			this.handleFilledPersonalNumber
		);
	}

	componentWillUnmount() {
		window.removeEventListener(
			'personal-number-filled',
			this.handleFilledPersonalNumber
		);
		window.clearTimeout(this.pingTimeoutId);
	}

	render() {
		const { personalNumber, qr, link } = this.state;

		const selectedBank = this.props.banks.find(
			(bank) => bank.id == this.state.selectedBank
		);

		let display = '';
		switch (this.state.stage) {
			case StageWaitingForSignature:
				display = (
					<WaitingForSignature
						qr={qr}
						link={link}
						isSwedbank={selectedBank && selectedBank.id === 'FSPA'}
					/>
				);
				break;
			case StageSelectAccount:
				display = (
					<AccountList
						accounts={this.state.accounts}
						selectedAccount={this.state.selectedAccount}
						handleChangeAccount={this.handleChangeAccount}
					/>
				);
				break;
			case StageError:
				display = <div>{this.state.errorMessage}</div>;
				break;
		}

		return (
			<div>
				<div className="tabbed-area__content-section grid">
					<div className="grid__col grid__col--md-1-1">
						<Input
							label="Personnr. (ÅÅÅÅMMDDXXXX)"
							type="text"
							name="bankid-personal-number"
							pattern="\d{4}\d{2}\d{2}-?\d{4}"
							showValidation={this.state.errorPersonalNumber}
							required
							value={personalNumber}
							onInput={this.handleChangePersonalNumber}
							disabled={this.state.loading}
						/>
						{this.state.errorPersonalNumber && (
							<span className="validation validation--error">
								{this.state.errorPersonalNumber}
							</span>
						)}
					</div>

					<div className="grid__col grid__col--4-5 grid__col--md-3-5">
						<select
							value={this.state.selectedBank || ''}
							onChange={this.handleChangeBank}
							className={this.state.bankWasChanged ? 'u-show-validation' : ''}
							disabled={this.state.loading}
							data-payment-bankid-bank
							required
						>
							<option value={''}>Välj bank…</option>
							{this.props.banks.map((bank) => (
								<option value={bank.id}>{bank.name}</option>
							))}
						</select>
						{selectedBank && selectedBank.disabled && (
							<span
								className="validation validation--error"
								key={`disabled-${selectedBank.id}`}
							>
								{selectedBank.disabled}
							</span>
						)}
						{selectedBank && selectedBank.disclaimer && (
							<span
								className="validation validation--error"
								key={`disclaimer-${selectedBank.id}`}
							>
								{selectedBank.disclaimer}
							</span>
						)}
						{this.state.errorSelectedBank && (
							<span className="validation validation--error">
								{this.state.errorSelectedBank}
							</span>
						)}
					</div>

					<div className="grid__col grid__col--1-1 bankid-button">
						<button
							className={
								'button bankid-button__button' +
								(this.state.stage === StageInitial ? ' button--filled' : '')
							}
							type="button"
							onClick={this.handleRequestAccounts}
							disabled={
								this.state.loading || (selectedBank && selectedBank.disabled)
							}
						>
							Hämta konton med Mobilt BankID
						</button>
						{this.state.loading ? (
							<div class="bankid-button__loading loading loading--margin-left">
								<span class="loading__label">
									{this.state.stage === StageContactingBank
										? 'Kontaktar banken…'
										: 'Väntar på underskrift…'}
								</span>
							</div>
						) : (
							<div className="bankid-button__symbol" />
						)}
					</div>
				</div>
				{display}
			</div>
		);
	}

	handleFilledPersonalNumber = (ev) => {
		if (ev.detail) {
			this.setState({ personalNumber: ev.detail });
		}
	};

	handleChangePersonalNumber = (value) => {
		this.setState({ personalNumber: value });
	};

	handleChangeBank = (ev) => {
		ev.preventDefault();
		this.setState({ selectedBank: ev.target.value, bankWasChanged: true });
	};

	handleRequestAccounts = (ev) => {
		ev.preventDefault();

		const validPersonalNumber = validatePersonalNumber(
			this.state.personalNumber
		);

		// Note: HTML field validation is used to show the status of the field,
		// but not to determine if the request should be sent or not.

		if (!this.state.personalNumber) {
			this.setState({ errorPersonalNumber: 'Personnummer måste fyllas i.' });
		} else if (!validPersonalNumber) {
			this.setState({
				errorPersonalNumber: 'Personnummer måste skrivas ÅÅÅÅMMDDXXXX.',
			});
		} else {
			this.setState({
				errorPersonalNumber: false,
			});
		}

		if (!this.state.selectedBank) {
			this.setState({ errorSelectedBank: 'Bank måste väljas.' });
		} else {
			this.setState({ errorSelectedBank: false });
		}

		if (!validPersonalNumber || !this.state.selectedBank) {
			return;
		}

		if (!this.state.loading) {
			this.pingForResponse();
		}
	};

	pingForResponse = async () => {
		this.setState({
			stage: StageContactingBank,
			loading: true,
			errorMessage: '',
			errorPersonalNumber: '',
			errorSelectedBank: '',
			qr: '',
			link: '',
			hasOpenedBankIDApp: false,
		});

		try {
			const deviceType = detectDeviceType();

			const { requestId } = await initiateRequest(this.props.endpoint, {
				method: 'post',
				body: JSON.stringify({
					personalNumber: this.state.personalNumber,
					bank: this.state.selectedBank,
					deviceType,
					_wpnonce: this.props.nonce,
				}),
			});
			const res = await pingForResponse(
				`${this.props.endpoint}/${requestId}`,
				(res) => {
					if (res && (res.link || res.qr)) {
						if (res.qr) {
							this.setState({ qr: res.qr });
						}
						if (res.link) {
							this.setState({ link: res.link });
							if (!this.state.hasOpenedBankIDApp && deviceType == 'mobile') {
								this.setState({ hasOpenedBankIDApp: true });
								window.location.replace(res.link);
							}
						}
						this.setState({ stage: StageWaitingForSignature });
					}
				}
			);

			this.setState({
				loading: false,
				stage: StageSelectAccount,
				accounts: res,
				qr: '',
				link: '',
				hasOpenedBankIDApp: false,
			});
		} catch (e) {
			this.setState({
				loading: false,
				stage: StageError,
				errorMessage: e.message,
				qr: '',
				link: '',
				hasOpenedBankIDApp: false,
			});
		}
	};

	handleChangeAccount = (ev) => {
		ev.preventDefault();
		this.setState({
			selectedAccount: ev.currentTarget.value,
		});
	};
}

const AccountList = ({ accounts, selectedAccount, handleChangeAccount }) => {
	return (
		<div className="tabbed-area__content-section tabbed-area__content-section--alternate">
			<h2 className="heading">Välj konto för autogiro</h2>
			<div
				className="radio-set radio-set--outlined radio-set--highlight-checked radio-set--vertical"
				data-radio-set
			>
				<input type="hidden" name="account" value={selectedAccount} />
				{accounts.map((account) => (
					<Account
						value={account.id}
						label={account.label}
						checked={selectedAccount === account.id}
						onChange={handleChangeAccount}
					/>
				))}
			</div>
		</div>
	);
};

const Account = ({ value, label, checked = false, onChange = () => { } }) => {
	let className = 'radio-set__option radio-set__option--small';
	if (checked) {
		className += ' radio-set__option--checked';
	}

	return (
		<label className={className}>
			<input
				type="radio"
				name="selectedAccount"
				value={value}
				checked={checked}
				onChange={onChange}
				required
			/>
			<span className="radio-set__label radio-set__label">{label}</span>
		</label>
	);
};

const WaitingForSignature = ({ qr, link, isSwedbank }) => {
	const deviceType = detectDeviceType();
	const mobileAndLink = deviceType === 'mobile' && link;

	const [mode, setMode] = useState(mobileAndLink ? linkMode : qrMode);

	const isQrMode = mode === qrMode;
	const isLinkMode = mode === linkMode;

	const temporarySolutionForSwedbank =
		deviceType === 'desktop' && qr && isSwedbank;

	return (
		<div className="tabbed-area__content-section tabbed-area__content-section--alternate waiting-for-signature">
			<img
				src={
					temporarySolutionForSwedbank
						? require('../../assets/qr-phone.svg')
						: require('../../assets/bankid-phone.svg')
				}
				alt=""
				className="waiting-for-signature__symbol"
			/>
			{isQrMode && (
				<div class="waiting-for-signature__mode">
					<p className="waiting-for-signature__message waiting-for-signature__message--qr">
						{temporarySolutionForSwedbank
							? 'Scanna koden med kamera-appen eller QR-läsare:'
							: 'Öppna Mobilt BankID och scanna denna kod:'}
					</p>
					<img src={qr} alt="" className="waiting-for-signature__qr" />
					{link && (
						<p class="waiting-for-signature__alt-action">
							<a href={link}>Öppna BankID-appen på den här enheten</a>
						</p>
					)}
				</div>
			)}
			{isLinkMode && (
				<div class="waiting-for-signature__mode">
					<p className="waiting-for-signature__message waiting-for-signature__message--qr">
						Öppna Mobilt BankID, slå in din kod och välj ”Legitimera”.
					</p>
					<a href={link} className="button waiting-for-signature__button">
						Öppna BankID-appen
					</a>
					<p class="waiting-for-signature__alt-action">
						<button type="button" class="link" onClick={() => setMode(qrMode)}>
							Öppna BankID på en annan enhet
						</button>
					</p>
				</div>
			)}
			<p>
				<small>
					Det kan ta upp till 45 sekunder att få återkoppling från din bank.
				</small>
			</p>
		</div>
	);
};
