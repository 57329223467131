import ToggleElement from './toggle-element';

export default class CartAddMore extends ToggleElement {
	expandedClassName = 'cart__add-more--expanded';

	constructor(el) {
		const controlsSelector = el.getAttribute('data-toggle-controls');
		const startExpanded = el.hasAttribute('data-toggle-start-expanded');
		if (!controlsSelector) return;

		const controlsEl = document.querySelector(controlsSelector);

		super({
			button: el,
			controls: controlsEl,
			expanded: startExpanded,
		});

		this.el = el;
		this.changedState(startExpanded);
	}

	changedState(expanded) {
		if (expanded) {
			this.el.classList.add(this.expandedClassName);
		} else {
			this.el.classList.remove(this.expandedClassName);
		}
	}
}
