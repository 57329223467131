import ToggleElement from './toggle-element';

export default class v2Foldable extends ToggleElement {
	constructor(el: HTMLElement) {
		const heading = el.querySelector<HTMLElement>('.v2-foldable__heading');
		const body = el.querySelector<HTMLElement>('.v2-foldable__body');

		if (!heading || !body) return;

		super({
			button: heading,
			controls: body,
		});

		this.el = el;
	}
}
