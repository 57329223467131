import {
	INPUT_MAX_LENGTH_INDICATOR_THRESHOLD,
	TEXTAREA_MAX_LENGTH_INDICATOR_THRESHOLD,
} from '../constants/input';

function showMaxLength(el) {
	return (
		el &&
		(el.tagName === 'TEXTAREA' || el.tagName === 'INPUT') &&
		el.getAttribute('maxlength')
	);
}

export default class Input {
	maxLengthIndicatorThreshold = INPUT_MAX_LENGTH_INDICATOR_THRESHOLD;
	focusedClassName = 'input--is-focused';
	contentClassName = 'input--has-content';

	constructor(el) {
		this.el = el;
		this.field = this.el.querySelector('.input__field');
		if (!this.field) {
			return;
		}

		this.field.addEventListener('focus', this.handleFocus);
		this.field.addEventListener('blur', this.handleBlur);
		this.field.addEventListener('keyup', this.handleContentChange);
		this.field.addEventListener('keydown', this.handleContentChange);
		this.field.addEventListener('change', this.handleContentChange);
		this.field.addEventListener('blur', this.handleContentChange);

		this.showMaxLength = showMaxLength(this.field);

		if (this.showMaxLength) {
			this.lengthIndicator = document.createElement('span');
			this.lengthIndicator.className = 'input__meta';

			if (this.field.tagName === 'INPUT') {
				this.lengthIndicator.className += ' input__meta--outside';
				this.maxLengthIndicatorThreshold = INPUT_MAX_LENGTH_INDICATOR_THRESHOLD;
			} else if (this.field.tagName === 'TEXTAREA') {
				this.maxLengthIndicatorThreshold =
					TEXTAREA_MAX_LENGTH_INDICATOR_THRESHOLD;
			}

			this.el.appendChild(this.lengthIndicator);
			this.updateMaxLength();
		}

		const hasLabel = this.el.querySelector('.input__label');
		if (!hasLabel) {
			this.field.classList.add('input__field--no-inline-label');
		}

		this.handleContentChange();
	}

	handleFocus = () => {
		this.el.classList.add(this.focusedClassName);
	};

	handleBlur = () => {
		this.el.classList.remove(this.focusedClassName);
	};

	handleContentChange = () => {
		const hasContent = this.field.value.length > 0;

		if (hasContent || this.field.validity.badInput) {
			this.el.classList.add(this.contentClassName);
		} else {
			this.el.classList.remove(this.contentClassName);
		}

		if (this.showMaxLength) {
			this.updateMaxLength();
		}
	};

	updateMaxLength = () => {
		const length = this.field.value.length || 0;
		const maxlength = this.field.getAttribute('maxlength');
		this.lengthIndicator.textContent = `${length} av max ${maxlength} tecken`;
		this.lengthIndicator.classList.toggle(
			'is-hidden',
			length <= maxlength * this.maxLengthIndicatorThreshold
		);
	};
}
