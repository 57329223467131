import anime from 'animejs';

const measureHeight = (el: HTMLElement) => {
	const cachedStyles = el.getAttribute('style');
	el.setAttribute('style', '');

	const height = el.offsetHeight;
	const { marginTop, marginBottom, paddingTop, paddingBottom } =
		window.getComputedStyle(el);

	if (cachedStyles) {
		el.setAttribute('style', cachedStyles);
	}

	return { height, marginTop, marginBottom, paddingTop, paddingBottom };
};

const utilMakeHeightSetting = (opts: anime.AnimeParams) => ({
	delay: 0,
	easing: 'easeOutQuint',
	...opts,
});

/**
 * Show and hide by sliding up and down
 */

const slideHeightDuration = 350;
const slideOpacityDuration = 200;

export function slideShow(el: HTMLElement, callback = () => {}) {
	const { height, marginTop, marginBottom, paddingTop, paddingBottom } =
		measureHeight(el);

	el.style.display = 'block';
	el.style.overflow = 'hidden';

	anime({
		targets: el,
		height: utilMakeHeightSetting({
			value: [0, height],
			duration: slideHeightDuration,
		}),
		marginTop: utilMakeHeightSetting({
			value: [0, marginTop],
			duration: slideHeightDuration,
		}),
		marginBottom: utilMakeHeightSetting({
			value: [0, marginBottom],
			duration: slideHeightDuration,
		}),
		paddingTop: utilMakeHeightSetting({
			value: [0, paddingTop],
			duration: slideHeightDuration,
		}),
		paddingBottom: utilMakeHeightSetting({
			value: [0, paddingBottom],
			duration: slideHeightDuration,
		}),
		opacity: {
			value: [0, 1],
			delay: slideHeightDuration - slideOpacityDuration,
			duration: slideOpacityDuration,
			easing: 'linear',
		},
		complete: () => {
			el.removeAttribute('style');
			callback();
		},
	});
}

export function slideHide(el: HTMLElement, callback = () => {}) {
	const heightSetting = utilMakeHeightSetting({
		value: 0,
		delay: 0,
		duration: slideHeightDuration,
	});

	const cachedOverflowProperty = el.style.overflow;
	el.style.overflow = 'hidden';

	anime({
		targets: el,
		height: heightSetting,
		marginTop: heightSetting,
		marginBottom: heightSetting,
		paddingTop: heightSetting,
		paddingBottom: heightSetting,
		opacity: {
			value: 0,
			delay: 0,
			duration: slideOpacityDuration,
			easing: 'linear',
		},
		complete: () => {
			el.style.display = 'none';
			el.style.overflow = cachedOverflowProperty;
			callback();
		},
	});
}

export function fadeShow(el: HTMLElement, callback = () => {}) {
	el.style.display = 'block';

	anime({
		targets: el,
		opacity: {
			value: [0, 1],
			duration: slideOpacityDuration,
			easing: 'linear',
		},
		complete: () => {
			el.removeAttribute('style');
			callback();
		},
	});
}

export function fadeHide(el: HTMLElement, callback = () => {}) {
	anime({
		targets: el,
		opacity: {
			value: 0,
			delay: 0,
			duration: slideOpacityDuration,
			easing: 'linear',
		},
		complete: () => {
			el.style.display = 'none';
			callback();
		},
	});
}
