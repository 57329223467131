import Popover from './popover';

let n = 0;

export default class CartWarning {
	constructor(el) {
		n++;

		this.el = el;

		this.isForm = this.el.nodeName === 'FORM';
		this.message = this.el.getAttribute('data-cart-warning');
		this.stayLink = this.el.getAttribute('data-cart-warning-stay-link');
		this.stayLabel =
			this.el.getAttribute('data-cart-warning-stay-label') ||
			'Slutför din gåva';
		this.continueLabel =
			this.el.getAttribute('data-cart-warning-continue-label') ||
			'Rensa och gå vidare';

		if (this.isForm) {
			this.el.addEventListener('submit', this.handlePrevent);
		} else {
			this.el.addEventListener('click', this.handlePrevent);
		}

		const popoverEl = document.createElement('div');
		popoverEl.id = 'popover-' + n;
		popoverEl.className = 'popover';
		popoverEl.innerHTML = `
			<button class="popover__close" data-popover-close>Stäng</button>
			<div class="popover__box">
				${this.message}
				<div class="popover__actions">
					<button class="button" data-cart-warning-continue>${this.continueLabel}</button>
					${
						this.stayLink
							? `<a class="button" href="${this.stayLink}">${this.stayLabel}</a>`
							: ''
					}
				</div>
			</div>
		`;

		this.popoverEl = popoverEl;
		document.body.appendChild(popoverEl);
		this.popover = new Popover(this.popoverEl);

		const continueEl = this.popoverEl.querySelector(
			'[data-cart-warning-continue]'
		);
		if (continueEl) {
			continueEl.addEventListener('click', this.handleContinue);
		}
	}

	handlePrevent = (ev) => {
		ev.preventDefault();
		this.popover.open(this.el);
	};

	handleContinue = () => {
		this.popover.close();

		switch (this.el.nodeName) {
			case 'FORM':
				this.el.removeEventListener('submit', this.handlePrevent);
				this.el.submit();
				break;

			case 'A':
				window.location.href = this.el.getAttribute('href');
				break;

			default:
				this.el.removeEventListener('click', this.handlePrevent);
				const clickEvent = new Event('click');
				this.el.dispatchEvent(clickEvent);
		}
	};
}
