import * as d3 from 'd3';

type ChartData = {
	name: string;
	value: number;
	color: string;
};

type ChartSettings = {
	width?: number;
	height?: number;
	innerRadius?: number;
	outerRadius?: number;
	labelRadius?: number;
	padAngle?: number;
};

function DonutChart(data: ChartData[], settings: ChartSettings) {
	const {
		width = 640, // outer width, in pixels
		height = 400, // outer height, in pixels
		innerRadius = Math.min(width, height) / 3, // inner radius of pie, in pixels (non-zero for donut)
		outerRadius = Math.min(width, height) / 2, // outer radius of pie, in pixels
		labelRadius = (innerRadius + outerRadius) / 2, // center radius of labels
		padAngle = 0, // angular separation between wedges
	} = settings;
	// Compute values.
	const N = d3.map(data, (d) => d.name);
	const V = d3.map(data, (d) => d.value);
	const C = d3.map(data, (d) => d.color);
	const I = d3.range(N.length).filter((i) => !isNaN(V[i]));

	// Construct scales.
	const color = d3.scaleOrdinal(N, C);

	// Construct arcs.
	const arcs = d3
		.pie()
		.padAngle(padAngle)
		.sort(null)
		.value((i) => V[i])(I);
	const arc = d3.arc().innerRadius(innerRadius).outerRadius(outerRadius);

	const svg = d3
		.create('svg')
		.attr('width', width)
		.attr('height', height)
		.attr('viewBox', [-width / 2, -height / 2, width, height]);

	svg
		.append('g')
		.selectAll('path')
		.data(arcs)
		.join('path')
		.attr('fill', (d) => color(N[d.data]))
		.attr('d', arc);

	return Object.assign(svg.node(), { scales: { color } });
}

const pieCharts = document.querySelectorAll('[data-pie-chart');

pieCharts.forEach((pieChart) => {
	const json = pieChart.getAttribute('data-pie-chart');
	if (!json) return;

	const data = JSON.parse(json);

	const chart = DonutChart(data, {
		width: 210,
		height: 210,
		innerRadius: 48,
	});

	pieChart.appendChild(chart);
});
