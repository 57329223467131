type FillTargetElement = HTMLInputElement | HTMLTextAreaElement;

/**
 * Set the value of a form element to the innerText or supplied value of the
 * attached element (should be a button) on click.
 */
export default class FillOnClick {
	el: HTMLButtonElement;
	target: FillTargetElement | null;

	constructor(el: HTMLButtonElement) {
		this.el = el;
		this.target = null;

		const { fillTarget } = el.dataset;

		if (fillTarget) {
			this.target = document.querySelector<FillTargetElement>(fillTarget);
		}

		if (!this.target) {
			return;
		}

		el.addEventListener('click', this.clickHandler.bind(this), false);
	}

	clickHandler() {
		const { el, target } = this;

		if (target) {
			const value = el.dataset.fillValue || el.innerText.trim();

			target.value = value;

			const keyupEvent = new Event('keyup');
			target.dispatchEvent(keyupEvent);
		}
	}
}
