import { h, FunctionalComponent as FC, Fragment } from 'preact';
import styles from './example-list.module.css';

type ExampleListProps = {
	examples: string[];
	selected: string;
	setSelected: (selected: string) => void;
};

const ExampleList: FC<ExampleListProps> = ({
	examples,
	selected,
	setSelected,
}) => {
	return (
		<Fragment>
			<p
				className="heading heading--no-underline"
				style={{ marginTop: '0.75rem', marginBottom: 0 }}
			>
				Välj en färdig hälsning
			</p>
			<div className={styles.exampleList}>
				{examples.map((example) => (
					<button
						type="button"
						aria-pressed={example === selected}
						className={[
							styles.exampleButton,
							example === selected && styles.exampleButtonSelected,
						].join(' ')}
						onClick={() => setSelected(example)}
					>
						<span style={styles.lineClamp}>{example}</span>
					</button>
				))}
			</div>
		</Fragment>
	);
};

export default ExampleList;
