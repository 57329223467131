const scrollTo = (el: HTMLElement, offset = 0) => {
	const scrollToOptionSupported =
		'scrollBehavior' in document.documentElement.style;
	const rect = el.getBoundingClientRect();
	const top = rect.top + window.pageYOffset + offset;

	if (scrollToOptionSupported) {
		window.scrollTo({
			left: 0,
			top,
			behavior: 'smooth',
		});
	} else {
		window.scrollTo(0, top);
	}
};

export default scrollTo;
