import throttle from 'lodash/throttle';
import Navigation from './navigation';

/**
 * Apply classes to page header
 */

export default class Header {
	el: HTMLElement;
	nav?: Navigation;
	menuOpenButtonEl: HTMLElement | null;
	menuCloseButtonEl: HTMLElement | null;
	miniHeaderClassName = 'header--mini';
	noTransitionsClassName = 'header--no-transitions';
	tabActivatedClassName = 'header--tab-activated';
	pxMargin = 2;

	isMini = false;

	constructor(el: HTMLElement) {
		this.el = el;

		// Get configuration from data attributes
		this.miniHeaderClassName =
			this.el.dataset.headerMiniHeaderClassName || this.miniHeaderClassName;
		this.noTransitionsClassName =
			this.el.dataset.headerNoTransitionsClassName ||
			this.noTransitionsClassName;
		this.tabActivatedClassName =
			this.el.dataset.headerTabActivatedClassName || this.tabActivatedClassName;

		// Initialize navigation
		const navEl = this.el.querySelector<HTMLElement>('[data-navigation]');
		if (navEl) {
			this.nav = new Navigation(navEl);
			this.nav.close();
		}

		this.menuOpenButtonEl = this.el.querySelector<HTMLElement>(
			'[data-menu-open-button]'
		);
		if (this.menuOpenButtonEl) {
			this.menuOpenButtonEl.addEventListener('click', this.handleMenuOpenClick);
		}

		this.menuCloseButtonEl = this.el.querySelector('[data-menu-close-button]');
		if (this.menuCloseButtonEl) {
			this.menuCloseButtonEl.addEventListener(
				'click',
				this.handleMenuCloseClick
			);
		}

		// Prevent the transition from playing if the window is scrolled when
		// the header is initialized.
		if (window.scrollY > this.pxMargin) {
			this.el.classList.add(this.noTransitionsClassName);
			this.el.classList.add(this.miniHeaderClassName);
			this.isMini = true;
			// Enable the transition again on the next tick
			window.setTimeout(() => {
				this.el.classList.remove(this.noTransitionsClassName);
			}, 0);
		}

		const throttledScroll = throttle(this.handleScroll, 50, {
			leading: true,
			trailing: true,
		});

		window.addEventListener('scroll', throttledScroll);
		document.addEventListener('keydown', this.handleDocKeydown);
	}

	handleMenuOpenClick = () => {
		this.nav?.open();
		this.menuCloseButtonEl?.focus();
	};

	handleMenuCloseClick = () => {
		this.nav?.close();
		this.menuOpenButtonEl?.focus();
	};

	handleScroll = () => {
		const scrollTop = window.scrollY;
		if (scrollTop <= this.pxMargin && this.isMini) {
			this.el.classList.remove(this.miniHeaderClassName);
			this.isMini = false;
		} else if (scrollTop > this.pxMargin && !this.isMini) {
			this.el.classList.add(this.miniHeaderClassName);
			this.isMini = true;
		}
	};

	handleDocKeydown = (event: KeyboardEvent) => {
		if (event.key === 'Tab') {
			this.el.classList.add(this.tabActivatedClassName);
		}
	};
}
