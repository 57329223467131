import { Ref, useCallback, useLayoutEffect, useState } from 'preact/hooks';

const useScaleFactor = (
	iframe: Ref<HTMLDivElement>,
	wrapper: Ref<HTMLDivElement>
) => {
	const [scaleFactor, setScaleFactor] = useState(1);

	const handleResize = useCallback(() => {
		if (!iframe.current || !wrapper.current) return;

		const wrapperRect = wrapper.current.getBoundingClientRect();

		const iframeElement =
			iframe.current instanceof HTMLIFrameElement
				? iframe.current
				: iframe.current.querySelector('iframe');

		if (!iframeElement) return;

		const factor = wrapperRect.width / Number(iframeElement.width);

		if (factor) {
			setScaleFactor(factor);
		}
	}, [iframe, wrapper]);

	useLayoutEffect(() => {
		const hasResizeObserver = typeof ResizeObserver !== 'undefined';
		const wrapperEl = wrapper.current;
		let resizeObserver: ResizeObserver | undefined;

		handleResize();

		if (!wrapperEl) return;

		if (hasResizeObserver) {
			resizeObserver = new ResizeObserver(handleResize);
			resizeObserver.observe(wrapperEl);
		} else {
			window.addEventListener('resize', handleResize);
		}
		return () => {
			if (hasResizeObserver) {
				resizeObserver?.disconnect();
				resizeObserver = undefined;
			} else {
				window.removeEventListener('resize', handleResize);
			}
		};
	}, [handleResize, wrapper]);

	return scaleFactor;
};

export default useScaleFactor;
