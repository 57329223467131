/**
 * Load third party scripts based on their purpose.
 */

document.addEventListener('DOMContentLoaded', () => {
	const scripts = document.querySelectorAll<HTMLScriptElement>(
		'script[data-script-purpose]'
	);

	/**
	 * Create a script element. The element can't just be cloned and reused if it
	 * was first prevented from loading with `type/text`, so it has to be recreated.
	 *
	 * @param script Script element
	 * @returns Newly created script element
	 */
	const createScript = (script: HTMLScriptElement): HTMLScriptElement => {
		const newScript = document.createElement('script');

		const setAttr = (attribute: string) => {
			if (script.hasAttribute(attribute)) {
				const value = script.getAttribute(attribute);
				newScript.setAttribute(attribute, value || '');
			}
		};

		[
			'src',
			'async',
			'crossorigin',
			'defer',
			'integrity',
			'nonce',
			'referrerpolicy',
		].forEach(setAttr);

		newScript.innerText = script.innerText;

		return newScript;
	};

	/**
	 * Load a script element by setting the type attribute.
	 *
	 * @param script Script element
	 * @returns void
	 */
	const loadScript = (script: HTMLScriptElement) => {
		/**
		 * If the element is not in the DOM tree, it has already been loaded
		 * and can be ignored.
		 */
		if (!script.parentNode) {
			return;
		}

		const parent = script.parentNode;
		script.parentNode.removeChild(script);
		script.dataset.loaded = 'yes';

		const newScript = createScript(script);

		(parent || document.head).appendChild(newScript);
	};

	/**
	 * Listen for when consent is given in the Cookie Tractor modal.
	 */
	window.addEventListener(
		'CookieConsentGiven',
		(event) => {
			const { consents } = event.detail;

			scripts.forEach((script) => {
				const purpose = script.getAttribute('data-script-purpose');

				if (purpose && consents.includes(purpose)) {
					loadScript(script);
				}
			});
		},
		false
	);
});
