/**
 * Outside Basic Multilingual Plane
 */

export function containsCharsOutsideBmp(subject: string) {
	return /[^\u{0000}-\u{FFFF}]/u.test(subject);
}

export function bmpFilter(subject: string) {
	return subject.replace(/([^\u{0000}-\u{FFFF}])/gu, '');
}

/**
 * Dingbats
 */

export function containsDingbats(subject: string) {
	return /[\u{2700}-\u{27BF}]/u.test(subject);
}

export function dingbatFilter(subject: string) {
	return subject.replace(/([\u{2700}-\u{27BF}])/gu, '');
}
