import {
	slideShow,
	slideHide,
	fadeShow,
	fadeHide,
} from '../utilities/animations';
import scrollTo from '../utilities/scroll-to';

type AnimationType = 'slide' | 'fade' | 'snap';

export default class Steps {
	el: HTMLElement;
	steps: Step[];
	currentStep = 0;

	constructor(el: HTMLElement) {
		this.el = el;

		this.steps = Array.from(
			el.querySelectorAll<HTMLElement>('[data-s-step]')
		).map((sel) => new Step(sel, this));

		this.goToIndex(0, true, true);

		// Add transitions after initial states have been set
		setTimeout(() => {
			this.steps.forEach((step) => {
				step.el.classList.add('steps__step--animated');
			});
		}, 1);
	}

	goNext = () => {
		const step = this.steps.find((_, i) => i === this.currentStep);
		if (step?.validate()) {
			this.currentStep += 1;
			this.goToIndex(this.currentStep);
		}
	};

	goPrev = () => {
		this.currentStep -= 1;
		this.goToIndex(this.currentStep);
	};

	goToIndex = (index: number, force = false, preventScroll = false) => {
		this.steps.forEach((step, i) => {
			if (index === i) {
				step.activate(force);
			} else {
				step.deactivate(force);
			}
		});

		this.currentStep = index;

		const previousStep =
			this.steps[this.currentStep - 1] || this.steps[this.currentStep];

		if (!preventScroll) {
			scrollTo(previousStep.el);
		}

		const radioSetEvents = new Event('radio-set-reevaluate-controls', {
			bubbles: true,
		});
		document.dispatchEvent(radioSetEvents);

		this.el.dispatchEvent(
			new CustomEvent('HideFormValidation', { bubbles: true })
		);

		const stepActivateEvent = new Event('step-activate', { bubbles: true });
		this.steps[this.currentStep].el.dispatchEvent(stepActivateEvent);
	};

	goToStep = (step: Step) => {
		const newIndex = this.steps.indexOf(step);
		const preventScroll = newIndex <= this.currentStep;
		this.goToIndex(this.steps.indexOf(step), false, preventScroll);
	};
}

class Step {
	el: HTMLElement;
	steps: Steps;

	nextButton?: HTMLButtonElement;
	prevButton?: HTMLButtonElement;
	goToButton?: HTMLButtonElement;
	stepsIndicator?: HTMLElement;
	indicator?: HTMLElement;
	body: HTMLElement[];
	buttons?: HTMLElement;

	isActive = false;
	hasBeenActive = false;

	constructor(el: HTMLElement, steps: Steps) {
		this.el = el;
		this.steps = steps;

		this.nextButton =
			el.querySelector<HTMLButtonElement>('[data-s-next-button]') || undefined;
		this.prevButton =
			el.querySelector<HTMLButtonElement>('[data-s-prev-button]') || undefined;
		this.goToButton =
			el.querySelector<HTMLButtonElement>('[data-s-go-to]') || undefined;
		this.stepsIndicator =
			el.querySelector<HTMLElement>('[data-s-step-indicator]') || undefined;
		this.indicator =
			this.el.querySelector<HTMLElement>('.step-indicator') || undefined;
		this.body = Array.from(
			this.el.querySelectorAll<HTMLElement>('.step__body')
		);
		this.buttons =
			this.el.querySelector<HTMLElement>('.step__buttons') || undefined;

		this.nextButton?.addEventListener('click', () => steps.goNext());
		this.prevButton?.addEventListener('click', () => steps.goPrev());
		this.goToButton?.addEventListener('click', () => steps.goToStep(this));
	}

	activate = (force = false) => {
		if (this.isActive && force === false) return;

		this.el.classList.remove('steps__step--animated');
		this.el.setAttribute('aria-expanded', 'true');

		const slideAnim = force ? 'snap' : 'slide';
		const fadeAnim = force ? 'snap' : 'fade';

		this.body.forEach((body) => this.showElement(body, slideAnim));
		this.showElement(this.buttons, slideAnim);
		this.hideElement(this.goToButton, fadeAnim);
		this.showElement(this.stepsIndicator, fadeAnim);

		setTimeout(() => {
			this.el.classList.add('steps__step--animated');
		}, 1);

		this.isActive = true;
		this.hasBeenActive = true;
	};

	deactivate = (force = false) => {
		if (!this.isActive && force === false) return;

		const slideAnim = force ? 'snap' : 'slide';
		const fadeAnim = force ? 'snap' : 'fade';

		this.body.forEach((body) => this.hideElement(body, slideAnim));
		this.hideElement(this.buttons, slideAnim);

		if (this.hasBeenActive) {
			this.showElement(this.goToButton, fadeAnim);
			this.hideElement(this.stepsIndicator, fadeAnim);
		} else {
			this.hideElement(this.goToButton, fadeAnim);
			this.hideElement(this.stepsIndicator, fadeAnim);
		}

		this.el.setAttribute('aria-expanded', 'false');

		this.isActive = false;
	};

	validate = () => {
		const fields = this.el.querySelectorAll<
			HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
		>(
			'input[data-validate], textarea[data-validate], select[data-validate], [data-fake-validation]'
		);

		let validates = true;

		Array.from(fields).forEach((field) => {
			if ('checkValidity' in field && !field.checkValidity()) {
				validates = false;
			}

			if (field.hasAttribute('data-fake-validation')) {
				if (field.getAttribute('data-fake-validation') === 'invalid') {
					validates = false;
				}
			}
		});

		if (validates) {
			this.indicator?.classList.add('step-indicator--filled');
		} else {
			this.indicator?.classList.remove('step-indicator--filled');
		}

		if (!validates) {
			this.el.dispatchEvent(
				new CustomEvent('StepValidationError', { bubbles: true })
			);
		}

		return validates;
	};

	showElement = (
		element: HTMLElement | undefined,
		animation: AnimationType
	) => {
		if (typeof element === 'undefined') return;

		switch (animation) {
			case 'slide':
				slideShow(element);
				break;
			case 'fade':
				fadeShow(element);
				break;
			case 'snap':
				element.style.removeProperty('display');
				break;
		}
	};

	hideElement = (
		element: HTMLElement | undefined,
		animation: AnimationType
	) => {
		if (typeof element === 'undefined') return;

		switch (animation) {
			case 'slide':
				slideHide(element);
				break;
			case 'fade':
				fadeHide(element);
				break;
			case 'snap':
				element.style.setProperty('display', 'none');
				break;
		}
	};
}
