import { slideShow, slideHide } from '../utilities/animations';
import makeId from '../utilities/make-id';

type ToggleElementOptions = {
	button: HTMLElement;
	controls?: HTMLElement;
	expanded?: boolean;
};

export default class ToggleElement {
	el: HTMLElement;
	controls?: HTMLElement;
	expanded: boolean;
	animating: boolean;

	constructor(opts: ToggleElementOptions) {
		this.el = opts.button;
		this.controls = opts.controls;
		this.expanded = opts.expanded || false;

		const controlsId =
			this.controls?.getAttribute('id') ||
			makeId(this.el.textContent || '', 'body-');

		this.animating = false;

		if (this.controls) {
			if (!this.expanded) {
				this.controls.style.display = 'none';
			}
			this.controls.setAttribute('id', controlsId);
		}

		if (this.el) {
			this.el.tabIndex = 0;
			this.el.setAttribute('aria-expanded', this.expanded.toString());
			this.el.setAttribute('aria-controls', controlsId);
			this.el.addEventListener('click', this.handleClick);
			this.el.addEventListener('keydown', this.handleKeydown);
			this.el.addEventListener('mousedown', this.handleMouseDown);
		}
	}

	handleClick = () => {
		if (this.animating) return;
		this.toggle();
	};

	handleKeydown = (event: KeyboardEvent) => {
		if (event.key === 'Enter') {
			this.toggle();
		}
	};

	handleMouseDown = (event: MouseEvent) => {
		if (event.detail > 1) {
			event.preventDefault();
		}
	};

	toggle = () => {
		if (this.expanded) {
			this.contract();
		} else {
			this.expand();
		}
	};

	expand = () => {
		this.animating = true;
		this.expanded = true;
		this.el.setAttribute('aria-expanded', 'true');
		this.controls?.setAttribute('aria-hidden', 'false');

		if (this.controls) {
			slideShow(this.controls, this.afterAnimating);
		}

		this.changedState(true);
	};

	contract = () => {
		this.animating = true;
		this.expanded = false;
		this.el.setAttribute('aria-expanded', 'false');
		this.controls?.setAttribute('aria-hidden', 'true');

		if (this.controls) {
			slideHide(this.controls, this.afterAnimating);
		}

		this.changedState(false);
	};

	afterAnimating = () => {
		this.animating = false;
	};

	changedState(expanded: boolean) {}
}
