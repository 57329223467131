import { slideShow } from '../utilities/animations';
import { initiateRequest, pingForResponse } from '../utilities/bankid';
import detectDeviceType from '../utilities/device-detection';
import SubmitLock from './submit-lock';

import bankidPhone from '../../assets/bankid-phone.svg';
import qrPhone from '../../assets/qr-phone.svg';

const qrModeAttribute = 'data-mode-qr';
const linkModeAttribute = 'data-mode-link';

export default class BankIDSubmit {
	el: HTMLFormElement;
	action: string;
	condition: string | null;

	submitLock: SubmitLock;

	takeover: HTMLDivElement;
	errorElement: HTMLElement | null;

	constructor(el: HTMLFormElement) {
		this.el = el;
		this.action = this.el.getAttribute('data-bankid-submit') || '';
		this.condition = this.el.getAttribute('data-bankid-submit-condition');

		this.submitLock = new SubmitLock(this.el, false);

		this.takeover = document.createElement('div');
		this.takeover.style.display = 'none';
		this.takeover.innerHTML = this.getTemplate();
		this.takeover.addEventListener('click', this.toggleModeHandler, true);
		this.el.appendChild(this.takeover);

		this.errorElement = this.el.querySelector('#form-error');

		this.el.addEventListener('submit', this.handleSubmit);
	}

	handleSubmit = async (ev: SubmitEvent) => {
		if (this.condition && !this.el.querySelector(this.condition)) {
			return;
		}

		if (this.errorElement) {
			this.errorElement.style.display = 'none';
			this.errorElement.textContent = '';
		}

		this.submitLock.lock();
		ev.preventDefault();

		const formData = new FormData(this.el);
		const fields: { [index: string]: FormDataEntryValue } = {};
		formData.forEach(function (value, key) {
			fields[key] = value;
		});

		const deviceType = detectDeviceType();
		fields.deviceType = deviceType;

		// Make sure that a bank account is selected in BankIDAccounts
		if (!formData.has('selectedAccount')) {
			if (this.errorElement) {
				this.errorElement.textContent =
					'Du måste välja ett konto för att betala med Mobilt BankID. Vänligen klicka på ”Hämta konton med Mobilt BankID” ovan.';
				slideShow(this.errorElement);
			}
			this.submitLock.unlock();
			return;
		}

		this.takeover.style.display = 'block';

		try {
			const deviceType = detectDeviceType();

			let cachedQr = '';
			let rendered = false;

			const { requestId } = await initiateRequest(this.action, {
				method: 'post',
				body: JSON.stringify(fields),
			});

			const isSwedbank =
				document.querySelector<HTMLSelectElement>('[data-payment-bankid-bank]')
					?.value === 'FSPA';

			const res = await pingForResponse<any, any>(
				`${this.action}/${requestId}`,
				(res) => {
					if (!rendered) {
						this.takeover.innerHTML = this.getTemplate({
							qr: res.qr,
							link: res.link,
							isSwedbank,
						});

						if (res.link && deviceType == 'mobile') {
							window.location.replace(res.link);
						}

						rendered = true;
					} else if (cachedQr !== res.qr) {
						const qrImg = this.takeover.querySelector(
							'[data-qr-code]'
						) as HTMLImageElement;
						if (qrImg && res.qr) {
							qrImg.src = res.qr;
						}
					}

					cachedQr = res.qr;
				}
			);

			if (res.confirmationPage) {
				console.log('Done', res);
				window.location.href = res.confirmationPage;
			}
		} catch (e) {
			if (this.errorElement && e instanceof Error) {
				this.errorElement.textContent = e.message;
				slideShow(this.errorElement);
			}

			this.takeover.style.display = 'none';
			this.submitLock.unlock();
		}
	};

	toggleModeHandler = (event: Event) => {
		const { target } = event;
		const { takeover } = this;

		if (target instanceof Element && target?.matches('[data-toggle-mode]')) {
			const qrMode = takeover.querySelector(
				`[${qrModeAttribute}]`
			) as HTMLElement;
			const linkMode = takeover.querySelector(
				`[${linkModeAttribute}]`
			) as HTMLElement;

			const qrModeActive = !qrMode?.hidden;
			const linkModeActive = !linkMode?.hidden;

			if (qrModeActive) {
				if (qrMode) {
					qrMode.hidden = true;
				}
				if (linkMode) {
					linkMode.hidden = false;
				}
			}

			if (linkModeActive) {
				if (qrMode) {
					qrMode.hidden = false;
				}
				if (linkMode) {
					linkMode.hidden = true;
				}
			}
		}
	};

	getTemplate = ({ qr = '', link = '', isSwedbank = false } = {}) => {
		const deviceType = detectDeviceType();
		const desktopAndQr = deviceType === 'desktop' && qr;
		const mobileAndLink = deviceType === 'mobile' && link;

		const temporarySolutionForSwedbank =
			deviceType === 'desktop' && qr && isSwedbank;

		return `
		<div class="bankid-sign-takeover">
			<div class="bankid-sign-takeover__block waiting-for-signature">
				<img
					src="${temporarySolutionForSwedbank ? qrPhone : bankidPhone}"
					alt=""
					class="waiting-for-signature__symbol"
				/>

				${qr
				? `
							<div class="waiting-for-signature__mode" data-mode-qr ${mobileAndLink ? 'hidden' : ''}
								<p class="waiting-for-signature__message waiting-for-signature__message--qr">
								${temporarySolutionForSwedbank
					? 'Scanna koden med kamera-appen eller QR-läsare:'
					: 'Öppna Mobilt BankID och scanna denna kod:'
				}
								</p>
                <img src="${qr}" alt="" class="waiting-for-signature__qr" data-qr-code>
							</div>
						`
				: ''
			}
				${link
				? `
							<div class="waiting-for-signature__mode" data-mode-link ${desktopAndQr ? 'hidden' : ''}>
								<p class="waiting-for-signature__message">
									Öppna Mobilt BankID, slå in din kod och välj ”Legitimera”.
								</p>
                <a href=${link} class="button waiting-for-signature__button">
									Öppna BankID-appen
								</a>
                ${qr
					? `
                      <p class="waiting-for-signature__alt-action">
                        <button type="button" class="link" data-toggle-mode>Öppna BankID på en annan enhet</button>
                      </p>
                      `
					: ''
				}
							</div>
						`
				: ''
			}
				<p class="waiting-for-signature__loading loading"><span class="loading__label">${qr || link ? 'Väntar på underskrift…' : 'Kontaktar banken…'}</span></p>
				<p>
					<small>
						Det kan ta upp till 45 sekunder att få återkoppling från din bank.
					</small>
				</p>
			</div>
		</div>
	`;
	};
}
