import ToggleElement from './toggle-element';

export default class Foldable extends ToggleElement {
	expandedClassName = 'foldable--expanded';

	constructor(el: HTMLElement) {
		const heading = el.querySelector<HTMLElement>('.foldable__heading');
		const body = el.querySelector<HTMLElement>('.foldable__body');

		if (!heading || !body) return;

		super({
			button: heading,
			controls: body,
		});

		this.el = el;
	}

	changedState(expanded: boolean) {
		if (expanded) {
			this.el.classList.add(this.expandedClassName);
		} else {
			this.el.classList.remove(this.expandedClassName);
		}
	}
}
