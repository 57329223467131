export default class MultiplyValues {
	constructor(el) {
		this.el = el;

		this.tokens = this.parse(el.getAttribute('data-multiply-values'));

		this.tokens
			.filter((token) => token.type === 'element')
			.forEach((token) => {
				token.element.addEventListener('keyup', this.recalculate);
				token.element.addEventListener('change', this.recalculate);
			});

		this.recalculate();
	}

	recalculate = () => {
		const value = this.tokens.reduce((acc, token) => {
			if (token.type === 'number') {
				return acc * token.value;
			}
			if (token.type === 'element') {
				return acc * token.element.value;
			}
			return acc;
		}, 1);

		if (this.el.tagName === 'INPUT') {
			this.el.value = value;
		} else {
			this.el.textContent = `${value} kr`;
		}
	};

	parse(input) {
		return input.split(';').map((token) => {
			if (!isNaN(Number(token))) {
				return {
					type: 'value',
					value: Number(token),
				};
			}

			try {
				const element = document.querySelector(token);
				if (!element || !['INPUT'].includes(element.tagName)) {
					throw new Error('Element is not valid');
				}
				return {
					type: 'element',
					element,
				};
			} catch (e) {
				console.warn(e);
			}

			return {
				type: 'invalid',
			};
		});
	}
}
