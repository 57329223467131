export const MIRROR_VALUE_TRIGGER_EVENT = 'mirror-value-trigger';

export default class FieldMirrorValue {
	el: HTMLInputElement;
	mirrorSelector: string | null;
	mirroredEls: HTMLElement[] = [];
	oneWay: boolean;

	constructor(el: HTMLInputElement) {
		this.el = el;
		this.mirrorSelector = this.el.getAttribute('data-mirror-value');
		if (this.mirrorSelector) {
			this.mirroredEls = Array.from(
				document.querySelectorAll<HTMLElement>(this.mirrorSelector)
			);
		}

		this.oneWay =
			this.el.hasAttribute('data-mirror-value-one-way') &&
			this.el.getAttribute('data-mirror-value-one-way') !== 'false';

		if (!this.oneWay) {
			this.el.addEventListener('change', this.handleUpdateValue);
			this.el.addEventListener(
				MIRROR_VALUE_TRIGGER_EVENT,
				this.handleUpdateValue
			);
		}

		this.mirroredEls.forEach((mEl) => {
			mEl.addEventListener('change', this.handleUpdateValue);
			mEl.addEventListener(MIRROR_VALUE_TRIGGER_EVENT, this.handleUpdateValue);
		});

		if (!(this.el instanceof HTMLInputElement)) {
			this.mirroredEls.forEach((el) => {
				if (!(el instanceof HTMLInputElement)) return;
				this.el.textContent = el.value;
			});
		}
	}

	handleUpdateValue = (ev: Event) => {
		if (!(ev.target instanceof HTMLInputElement)) {
			return;
		}

		[this.el, ...this.mirroredEls].forEach((el) => {
			if (el !== ev.target && ev.target instanceof HTMLInputElement) {
				if (
					!ev.target.hasAttribute('data-mirror-prevent-input-export') &&
					el instanceof HTMLInputElement &&
					!el.hasAttribute('data-mirror-prevent-input-import')
				) {
					el.value = ev.target.value;
					fireKeyUpChange(el);
				} else {
					el.textContent = ev.target.value;
				}
			}
		});
	};
}

const fireKeyUpChange = (el: HTMLElement) => {
	let keyupEvent;

	if (typeof Event === 'function') {
		keyupEvent = new Event('keyup');
	} else {
		keyupEvent = document.createEvent('Event');
		keyupEvent.initEvent('keyup', true, true);
	}

	el.dispatchEvent(keyupEvent);
};
