import { Field } from '../types';

export default function getFieldValue(
	field: Field,
	fieldValues: Record<string, string>
): string {
	if (typeof fieldValues[field.id] === 'string') {
		return fieldValues[field.id];
	}

	return field.defaultValue || '';
}
