import { h, Component, createRef, JSX, RefObject, Fragment } from 'preact';

import {
	INPUT_MAX_LENGTH_INDICATOR_THRESHOLD,
	TEXTAREA_MAX_LENGTH_INDICATOR_THRESHOLD,
} from '../constants/input';

type InputProps = {
	value: string;
	name?: string;
	label?: string;
	placeholder?: string;
	validate?: boolean;
	showValidation?: boolean;
	validationMessage?: string | null;
	autoFocus?: boolean;
	autoSelect?: boolean;
	required?: boolean;
	maxLength?: number;
	maxLineBreaks?: number;
	autocomplete?: string;
	rows?: number;
	fieldRef?: RefObject<HTMLTextAreaElement | HTMLInputElement>;
	as?: 'input' | 'textarea';
	onInput?: (value: string, name?: string) => void;
	onFocus?: () => void;
	onBlur?: () => void;
	onKeyDown?: (
		event: JSX.TargetedKeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
	) => void;
	onKeyUp?: (
		event: JSX.TargetedKeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
	) => void;
	style?: JSX.CSSProperties;
};

type InputState = {
	isFocused: boolean;
	wasChanged: boolean;
	showValidationStatus: boolean;
};

type MismatchKey = `${HTMLInputElement['type']}Mismatch`;

export default class Input extends Component<InputProps, InputState> {
	focusedClassName = 'input--is-focused';
	contentClassName = 'input--has-content';
	showValidationStatusClassName = 'u-show-validation';
	hasValidation = false;
	maxLengthIndicatorThresholds = {
		input: INPUT_MAX_LENGTH_INDICATOR_THRESHOLD,
		textarea: TEXTAREA_MAX_LENGTH_INDICATOR_THRESHOLD,
	};

	input = createRef<HTMLInputElement>();
	textarea = createRef<HTMLTextAreaElement>();

	errorMessages: Record<string, string> = {
		valueMissing: 'Vänligen fyll i fältet.',
		emailMismatch: 'Ange en giltig e-postadress.',
		patternMismatch: 'Värdet är inte rätt formaterat.',
	};

	constructor(props: InputProps) {
		super(props);
		this.state = {
			isFocused: false,
			wasChanged: this.props.value?.length > 0,
			showValidationStatus: this.props.value?.length > 0,
		};
	}

	componentDidUpdate(prevProps: InputProps) {
		// If the value changes externally, without onChange firing on the field,
		// we still need to show validation status.
		if (this.props.value !== prevProps.value) {
			this.setState({ wasChanged: true });
		}

		if (this.props.validationMessage !== prevProps.validationMessage) {
			const el = this.input.current || this.textarea.current;

			if (el) {
				el.setCustomValidity(this.props.validationMessage || '');

				el.reportValidity();
			}
		}
	}

	componentDidMount(): void {
		if (this.props.autoFocus) {
			this.input.current?.focus();
			this.textarea.current?.focus();
		}

		if (this.props.autoSelect) {
			this.input.current?.select();
			this.textarea.current?.select();
		}
	}

	render() {
		const {
			label,
			maxLength,
			fieldRef,
			as: asElement = 'input',
			...props
		} = this.props;

		const maxLengthIndicatorThreshold =
			this.maxLengthIndicatorThresholds[asElement] || 0;

		let classNames = 'input';
		if (this.state.isFocused) {
			classNames += ' ' + this.focusedClassName;
		}
		if (this.props.value?.length > 0) {
			classNames += ' ' + this.contentClassName;
		}

		const hasPlaceholder =
			typeof this.props.placeholder === 'string' &&
			this.props.placeholder.length > 0;

		let inputClassNames = 'input__field';
		if (this.state.showValidationStatus || this.props.showValidation) {
			inputClassNames += ' ' + this.showValidationStatusClassName;
		}

		if (hasPlaceholder) {
			inputClassNames += ' input__field--no-inline-label';
		}

		return (
			<Fragment>
				{hasPlaceholder ? (
					<p
						className="heading heading--no-underline"
						style={{ marginBottom: 0 }}
					>
						{this.props.label}
					</p>
				) : null}
				<label className={classNames} style={this.props.style}>
					{!hasPlaceholder && <span className="input__label">{label}</span>}
					{asElement === 'input' && (
						<input
							className={inputClassNames}
							{...props}
							onInput={this.handleInput}
							onFocus={this.handleFocus}
							onBlur={this.handleBlur}
							onKeyUp={this.props.onKeyUp}
							onKeyDown={this.handleKeyDown}
							onInvalid={this.handleInvalid}
							required={this.props.required}
							maxLength={maxLength}
							autocomplete={this.props.autocomplete}
							ref={(el) => {
								this.input.current = el;
								if (fieldRef) {
									fieldRef.current = el;
								}
							}}
						/>
					)}
					{asElement === 'textarea' && (
						<textarea
							className={inputClassNames}
							{...props}
							onInput={this.handleInput}
							onFocus={this.handleFocus}
							onBlur={this.handleBlur}
							onKeyUp={this.props.onKeyUp}
							onKeyDown={this.handleKeyDown}
							onInvalid={this.handleInvalid}
							required={this.props.required}
							maxLength={maxLength}
							ref={(el) => {
								this.textarea.current = el;
								if (fieldRef) {
									fieldRef.current = el;
								}
							}}
						/>
					)}
					{maxLength &&
						this.props.value.length >
							maxLength * maxLengthIndicatorThreshold && (
							<span className="input__meta">
								{this.props.value.length || 0} av max {maxLength} tecken
							</span>
						)}
					<span class="validation validation--error" aria-live="polite">
						{this.props.validationMessage && this.props.validationMessage}
					</span>
				</label>
			</Fragment>
		);
	}

	handleInput = (
		ev: JSX.TargetedEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		this.setState({ wasChanged: true });
		if (
			typeof this.props.onInput === 'function' &&
			(ev.target instanceof HTMLInputElement ||
				ev.target instanceof HTMLTextAreaElement)
		) {
			this.props.onInput(ev.target.value, this.props.name);
		}
	};

	handleKeyDown = (
		ev: JSX.TargetedKeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
	) => {
		if (this.props.onKeyDown) {
			this.props.onKeyDown(ev);
		}

		if (this.props.maxLineBreaks) {
			const linebreaks = (this.props.value.match(/\n/g) || '').length + 1;
			const endsInDoubleLinebreak = this.props.value.match(/\n{2,}$/);
			if (
				ev.key === 'Enter' &&
				(linebreaks > this.props.maxLineBreaks || endsInDoubleLinebreak)
			) {
				ev.preventDefault();
			}
		}
	};

	handleFocus = () => {
		this.setState({ isFocused: true });
		if (typeof this.props.onFocus === 'function') {
			this.props.onFocus();
		}
	};

	handleBlur = () => {
		this.setState((prevState) => ({
			isFocused: false,
			showValidationStatus: prevState.wasChanged,
		}));

		if (typeof this.props.onBlur === 'function') {
			this.props.onBlur();
		}
	};

	handleInvalid = (e: Event) => {
		// Prevent the browser's default validation UI from showing
		e.preventDefault();
	};
}
