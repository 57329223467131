export default class FileInput {
	focusedClassName = 'file-input--is-focused';
	contentClassName = 'file-input--has-file';

	constructor(el) {
		this.el = el;
		this.field = this.el.querySelector('.file-input__field');
		this.label = this.el.querySelector('.file-input__label');

		if (!this.field) {
			return;
		}

		this.originalLabel = this.label.textContent;

		this.field.addEventListener('focus', this.handleFocus);
		this.field.addEventListener('blur', this.handleBlur);
		this.field.addEventListener('keyup', this.handleContentChange);
		this.field.addEventListener('change', this.handleContentChange);
		this.field.addEventListener('blur', this.handleContentChange);
		this.field.addEventListener('invalid', this.handleInvalid);
		this.field.addEventListener('change', this.handleChange);

		this.handleContentChange();
	}

	handleFocus = () => {
		this.el.classList.add(this.focusedClassName);
	};

	handleBlur = () => {
		this.el.classList.remove(this.focusedClassName);
	};

	handleContentChange = () => {
		if (this.field.files.length > 1) {
			this.label.textContent = `${this.field.files.length} filer valda`;
		} else if (this.field.files.length === 1) {
			this.label.textContent = `${this.field.files[0].name}`;
		} else {
			this.label.textContent = this.originalLabel;
		}

		if (this.field.files.length > 0) {
			this.el.classList.add(this.contentClassName);
		} else {
			this.el.classList.remove(this.contentClassName);
		}
	};

	handleChange = () => {
		this.el.classList.remove('file-input--is-invalid');
		this.el.classList.remove('file-input--is-valid');
		if (this.field.files.length > 0 && this.field.validity.valid) {
			this.el.classList.add('file-input--is-valid');
		} else if (
			this.field.hasAttribute('required') &&
			this.field.files.length === 0
		) {
			this.el.classList.add('file-input--is-invalid');
		}
	};

	handleInvalid = () => {
		if (this.field.files.length > 0) {
			this.el.classList.remove('file-input--is-valid');
			this.el.classList.add('file-input--is-invalid');
		}
	};
}
