import { z } from 'zod';

export const imageSchema = z.object({
	value: z.string(),
	src: z.string(),
});

export const baseFieldSchema = z.object({
	id: z.string(),
	area: z.string(),
	name: z.string(),
	defaultValue: z.string(),
	autocomplete: z.string().optional(),
});

export const imageFieldSchema = baseFieldSchema.merge(
	z.object({
		type: z.literal('image'),
		presentation: z.union([z.literal('inline'), z.literal('modal')]),
		images: z.array(imageSchema),
	})
);

export const textFieldSchema = baseFieldSchema.merge(
	z.object({
		type: z.union([z.literal('text'), z.literal('textarea')]),
		maxLength: z.number().optional(),
		maxLineBreaks: z.number().optional(),
		rows: z.number().optional(),
		placeholder: z.string().optional(),
		exampleValues: z.array(z.string()).optional(),
		required: z.boolean().optional(),
		validateNoEmoji: z.boolean().optional(),
	})
);

export const fieldSchema = z.union([imageFieldSchema, textFieldSchema]);

export const fieldsSchema = z.array(fieldSchema);

export const stringsSchema = z.record(z.string(), z.string());

export const settingsSchema = z.object({
	cardUrl: z.string(),
	strings: stringsSchema,
	fields: fieldsSchema,
});
