import { h, FunctionalComponent as FC, JSX, Fragment } from 'preact';
import {
	useEffect,
	useRef,
	useState,
	useCallback,
	useMemo,
} from 'preact/hooks';
import useScaleFactor from './hooks/use-scale-factor';
import useScrollLock from './hooks/use-scroll-lock';
import { Field, FrameArea, MessageUpdateRect } from './types';
import EditSymbol from './edit-symbol';
import Frame from './frame';
import styles from './frame-overlay.module.css';

type FrameOverlayProps = {
	src: string;
	width: number;
	height: number;
	activeArea?: string;
	fields: Field[];
	fieldValues: Record<string, string>;
	editable?: boolean;
	onClick?: (id: string) => void;
	onCoverClick?: () => void;
	style?: JSX.CSSProperties;
	children: (fields: Field[]) => JSX.Element | JSX.Element[];
};

const FrameOverlay: FC<FrameOverlayProps> = ({
	src,
	width,
	height,
	activeArea,
	fields,
	fieldValues,
	editable = true,
	onClick: onAreaClick,
	onCoverClick,
	style,
	children,
}) => {
	const wrapper = useRef<HTMLDivElement>(null);
	const iframe = useRef<HTMLDivElement>(null);
	const overlay = useRef<HTMLDivElement>(null);
	const fieldContainer = useRef<HTMLDivElement>(null);
	const scaleFactor = useScaleFactor(iframe, wrapper);

	const [showValidationErrors, setShowValidationErrors] = useState(false);
	useEffect(() => {
		if (!editable) return;

		const step = wrapper.current?.closest('.step');
		if (!step) return;

		const handleStepValidationError = () => {
			setShowValidationErrors(true);

			setTimeout(() => {
				const invalidButton = wrapper.current?.querySelector<HTMLElement>(
					`.${styles.buttonError}`
				);

				if (!invalidButton) return;

				const rect = invalidButton.getBoundingClientRect();
				const scrollMargin = 140;

				if (rect.top < scrollMargin) {
					window.scrollTo({
						top: window.scrollY + rect.top - scrollMargin,
						behavior: 'smooth',
					});
				}
			}, 1);
		};

		step.addEventListener('StepValidationError', handleStepValidationError);

		return () => {
			step.removeEventListener(
				'StepValidationError',
				handleStepValidationError
			);
		};
	}, [editable, wrapper]);

	const [areas, setAreas] = useState<FrameArea[]>([]);

	const updateAreas = useCallback((message: MessageUpdateRect) => {
		setAreas((prev) => {
			const newAreas = [...prev];
			const existing = newAreas.findIndex((a) => a.id === message.id);
			if (existing > -1) {
				newAreas[existing] = {
					...newAreas[existing],
					rect: message.rect,
				};
				return newAreas;
			} else {
				return [
					...newAreas,
					{
						id: message.id,
						rect: message.rect,
					},
				];
			}
		});
	}, []);

	const disableButtons = activeArea !== undefined;

	const positionFromRect = useCallback(
		(rect: DOMRect, padding = 0) => ({
			top: rect.top * scaleFactor - padding,
			left: rect.left * scaleFactor - padding,
			width: rect.width * scaleFactor + padding * 2,
			height: rect.height * scaleFactor + padding * 2,
		}),
		[scaleFactor]
	);

	const currentFields = fields.filter((f) => f.area === activeArea);

	useScrollLock(overlay, editable && currentFields.length > 0);

	/**
	 * The image field's value should be provided to the iframe as the image's
	 * src, not the value.
	 */
	const preparedFieldValues = useMemo(() => {
		const prepared = { ...fieldValues };

		Object.entries(fieldValues).forEach(([key, value]) => {
			const field = fields.find((f) => f.id === key);
			if (!field || field.type !== 'image') return;

			const image = field.images.find((i) => i.value === value);
			if (!image) return;

			prepared[key] = image.src;
		});

		return prepared;
	}, [fields, fieldValues]);

	const [transitioning, setTransitioning] = useState(false);
	useEffect(() => {
		const handleTransitioningState = () => {
			setTransitioning(true);
			window.setTimeout(() => {
				setTransitioning(false);
			}, 375); // delay necessary to wait for steps form transition to end
		};

		document.addEventListener('step-activate', handleTransitioningState);

		return () => {
			document.removeEventListener('step-activate', handleTransitioningState);
		};
	}, []);

	const handleCoverClick = (event: MouseEvent) => {
		if (onCoverClick && event.target === overlay.current) {
			onCoverClick();
		}
	};

	return (
		<Fragment>
			{currentFields.length > 0 && (
				<div className={styles.cover} ref={overlay} onClick={handleCoverClick}>
					<div ref={fieldContainer} className={styles.field}>
						{children(currentFields)}
					</div>
				</div>
			)}
			<div
				className={styles.wrapper}
				style={{
					...style,
					aspectRatio: `${width} / ${height}`,
					maxWidth: width,
				}}
				ref={wrapper}
			>
				{editable &&
					areas.map((area) => {
						const inlineFields = fields.filter(
							(f) =>
								f.area === area.id &&
								f.type === 'image' &&
								f.presentation === 'inline'
						);

						if (inlineFields.length > 0) {
							return (
								<div
									key={area.id}
									className={styles.button}
									style={{
										...positionFromRect(area.rect),
										alignItems: 'flex-end',
									}}
								>
									{children(inlineFields)}
								</div>
							);
						}

						const field = fields.find((f) => f.area === area.id);
						const fieldValue = field ? fieldValues[field.id] : undefined;

						return (
							<button
								key={area.id}
								disabled={disableButtons}
								data-button-id={area.id}
								type="button"
								className={[
									styles.button,
									styles.buttonHighlight,
									fieldValue && styles.buttonFilled,
									showValidationErrors && !fieldValue && styles.buttonError,
								]
									.filter(Boolean)
									.join(' ')}
								style={{
									cursor: disableButtons ? 'default' : 'pointer',
									opacity: disableButtons || transitioning ? 0 : 1,
									...positionFromRect(area.rect, 4),
									...(area.id === 'image' ? { alignItems: 'flex-start' } : {}),
								}}
								onClick={() => {
									if (onAreaClick) onAreaClick(area.id);
								}}
								// @todo: Set proper label
								aria-label={'Redigera ' + area.id}
							>
								<EditSymbol
									className={styles.editSymbol}
									style={{
										...(area.id === 'image'
											? { transform: 'translate(-0.5rem, 0.5rem) ' }
											: {}),
									}}
								/>
							</button>
						);
					})}

				<Frame
					src={src}
					width={width}
					height={height}
					innerRef={iframe}
					scaleFactor={scaleFactor}
					editable={editable}
					fieldValues={preparedFieldValues}
					onRectsUpdate={updateAreas}
				/>
			</div>
		</Fragment>
	);
};

export default FrameOverlay;
