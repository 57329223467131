import { h, Ref } from 'preact';
import { useEffect } from 'preact/hooks';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

export default function useScrollLock(
	component: Ref<HTMLDivElement | null>,
	lock: boolean
) {
	useEffect(() => {
		if (!component || !component.current) return;

		let currentEl = component.current;

		if (lock) {
			disableBodyScroll(currentEl, { reserveScrollBarGap: true });
		} else {
			enableBodyScroll(currentEl);
		}

		return () => {
			enableBodyScroll(currentEl);
		};
	}, [component, lock]);
}
