import anime from 'animejs';

const PREFERS_REDUCED_MOTION_MEDIA_QUERY = '(prefers-reduced-motion: reduce)';

export default class ChristmasCampaign {
	el: HTMLElement;
	currentStageNumber: number = 0;
	currentStage?: HTMLElement;
	stage1Element: HTMLElement;
	stage2Element: HTMLElement;

	heartClassName = 'christmas-campaign__heart christmas-campaign__random-heart';
	initialDelay = 500;
	swapFadeDuration = 380;
	fadeEasing = 'easeOutQuad';
	hearts = 30;

	constructor(el: HTMLElement) {
		this.el = el;

		this.stage1Element = <HTMLElement>(
			el.querySelector('[data-christmas-stage-1]')
		);
		this.stage2Element = <HTMLElement>(
			el.querySelector('[data-christmas-stage-2]')
		);

		if (!this.stage1Element || !this.stage2Element) {
			return;
		}

		this.stage1Element.hidden = false;
		this.stage2Element.hidden = true;

		if (document.readyState === 'complete') {
			this.playStage(1);
		} else {
			window.addEventListener('load', () =>
				this.playStage(1, this.initialDelay)
			);
		}
	}

	async playStage(stage: number, initialDelay: number = 0) {
		const currentStage = stage === 1 ? this.stage1Element : this.stage2Element;

		const previousStage = this.currentStage;

		this.currentStageNumber = stage;
		this.currentStage = currentStage;

		const textHeart = <HTMLElement>currentStage.querySelector('[data-heart]');
		const textHeartContent = <HTMLElement>textHeart.firstElementChild;

		if (previousStage) {
			await anime({
				targets: previousStage,
				opacity: [1, 0],
				duration: this.swapFadeDuration,
				easing: this.fadeEasing,
			}).finished;

			anime.set(currentStage, { opacity: 0 });
		}

		const hearts = this.addHearts(currentStage);

		anime.set(textHeart, { scale: 0.9 });
		anime.set(textHeartContent, { opacity: 0 });
		anime.set(hearts, { opacity: 0 });

		if (previousStage) {
			previousStage.hidden = true;
		}

		currentStage.hidden = false;

		if (previousStage) {
			await anime({
				targets: currentStage,
				opacity: [0, 1],
				easing: this.fadeEasing,
				duration: this.swapFadeDuration,
			}).finished;
		}

		await anime
			.timeline({
				delay: initialDelay,
			})
			.add(
				{
					targets: textHeart,
					scale: 1,
					easing: 'easeOutBack',
					duration: 650,
				},
				0
			)
			.add(
				{
					targets: textHeartContent,
					opacity: 1,
					easing: this.fadeEasing,
					duration: 300,
				},
				200
			)
			.add(
				window.matchMedia(PREFERS_REDUCED_MOTION_MEDIA_QUERY).matches
					? {}
					: {
							targets: hearts,
							keyframes: [{ opacity: [0, 1] }, { opacity: [1, 0] }],
							scale: () => anime.random(5, 9),
							easing: 'easeOutQuad',
							duration: 1500,
							delay: anime.stagger(`+${anime.random(300, 600)}`),
						}
			)
			.add({}, 5000).finished;

		for (const heart of hearts) {
			if (heart.parentElement) {
				heart.parentElement.removeChild(heart);
			}
		}

		this.playStage(stage === 1 ? 2 : 1, initialDelay);
	}

	addHearts(stageElement: HTMLElement): HTMLElement[] {
		const frag = document.createDocumentFragment();

		const hearts = [...Array(this.hearts)].map(() => {
			const heart = document.createElement('div');
			heart.className = this.heartClassName;
			heart.role = 'presentation';
			heart.style.setProperty(
				'--heart-size',
				`clamp(1rem, ${anime.random(200, 500) / 100}%, 15%)`
			);
			heart.style.setProperty('top', `${anime.random(1, 100)}%`);
			heart.style.setProperty('left', `${anime.random(1, 100)}%`);
			heart.style.setProperty('z-index', `${anime.random(-1, 0)}`);

			frag.appendChild(heart);

			return heart;
		});

		stageElement.appendChild(frag);

		return hearts;
	}
}
