.imageList {
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	gap: 1rem;
}

.imageButton {
	appearance: none;
	border: 0;
	padding: 0;
	background: #999;
	overflow: hidden;
	box-shadow: 0 0 0 0 var(--color-blue);
	transition: box-shadow 100ms ease-out;
}

.imageButtonSelected {
	box-shadow: 0 0 0 3px var(--color-blue);
}

.image {
	display: block;
	width: 100%;
	height: auto;
	aspect-ratio: 4/3;
	object-fit: cover;
	cursor: pointer;
	transition: transform 250ms ease-in-out, opacity 250ms linear;
}

.imageButton:hover .image {
	transform: scale(1.03);
}

.imageList:has(.imageButton:hover) .imageButton:not(:hover) .image {
	opacity: 0.6;
}
