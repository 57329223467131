import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock';

/**
 * Handle the header navigation menu
 */

export default class Navigation {
	el: HTMLElement;
	scrollEl?: HTMLElement | null;
	scrollElSelector = '.navigation__wrap';
	focusTimeoutId?: number;
	openClassName = 'navigation--open';
	useHiddenAttribute = false;
	isOpen = false;

	constructor(el: HTMLElement) {
		this.el = el;
		if (!el) return;

		this.scrollElSelector =
			this.el.dataset.navigationScrollElSelector || this.scrollElSelector;
		this.openClassName =
			this.el.dataset.navigationOpenClassName || this.openClassName;
		this.useHiddenAttribute =
			this.el.dataset.navigationUseHiddenAttribute === 'true';

		this.scrollEl = this.el.querySelector(this.scrollElSelector);
		window.addEventListener('focus', this.handleWindowFocus, true);
		this.el.addEventListener('transitionend', this.handleTransitionEnd);

		if (this.useHiddenAttribute) {
			this.el.hidden = true;
		} else {
			this.el.style.display = 'none';
		}

		this.el.addEventListener('click', this.handleContainerClick);
	}

	open = () => {
		if (this.useHiddenAttribute) {
			this.el.hidden = false;
		} else {
			this.el.style.display = 'block';
		}
		this.isOpen = true;
		setTimeout(() => {
			this.el.classList.add(this.openClassName);
		}, 1);
		if (this.scrollEl) {
			this.scrollEl.scrollTop = 0;
			disableBodyScroll(this.scrollEl);
		}
	};

	close = () => {
		this.isOpen = false;
		this.el.classList.remove(this.openClassName);
		if (this.scrollEl) {
			enableBodyScroll(this.scrollEl);
		}
	};

	toggle = (force?: boolean) => {
		if (this.isOpen || force === false) {
			this.close();
		} else if (!this.isOpen || force === true) {
			this.open();
		}
	};

	handleTransitionEnd = () => {
		if (!this.isOpen) {
			if (this.useHiddenAttribute) {
				this.el.hidden = true;
			} else {
				this.el.style.display = 'none';
			}
		}
	};

	handleWindowFocus = (event: FocusEvent) => {
		if (!this.isOpen) return;
		window.clearTimeout(this.focusTimeoutId);
		this.focusTimeoutId = window.setTimeout(() => {
			if (
				event.target instanceof HTMLElement &&
				this.el.contains(event.target) === false
			) {
				const first = this.el.querySelector<HTMLElement>('button, a, input');
				if (first) {
					first.focus();
				}
			}
		}, 1);
	};

	handleContainerClick = (event: MouseEvent) => {
		if (event.target === this.el) {
			this.close();
		}
	};
}
