/**
 * Handle lazy loading images
 */

/* Native lazy-loading support is disabled because it couldn't be made to work
 * in Chrome Canary (77) in June 2019. Setting the src attribute seems to make
 * the image load immediately, despite loading="lazy" and the element being
 * outside the viewport. / PS */
const clientSupportsLoading = false; // 'loading' in HTMLImageElement.prototype;

const observerOptions = {
	rootMargin: '80px',
};

export default class LazyLoadImage {
	constructor(el) {
		this.el = el;
		if (!el) return;

		if (clientSupportsLoading) {
			this.el.setAttribute('loading', 'lazy');
			this.setSrc();
		} else {
			this.setUpObserver();
		}
	}

	setUpObserver = () => {
		this.observer = new IntersectionObserver(
			this.intersectionCallback,
			observerOptions
		);
		this.observer.observe(this.el);
	};

	removeObserver = () => {
		this.observer.unobserve(this.el);
		this.observer = null;
	};

	intersectionCallback = (entries, observer) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				this.setSrc();
				this.removeObserver();
			}
		});
	};

	setSrc = () => {
		const dataSrcSet = this.el.getAttribute('data-srcset');
		const dataSrc = this.el.getAttribute('data-src');

		if (dataSrcSet) {
			this.el.setAttribute('srcset', dataSrcSet);
			this.el.removeAttribute('data-srcset');
		}

		if (dataSrc) {
			this.el.setAttribute('src', dataSrc);
			this.el.removeAttribute('data-src');
		}
	};
}
