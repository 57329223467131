import anime from 'animejs';

const numbers = document.querySelectorAll<HTMLElement>('[data-count-up]');

const observer = new IntersectionObserver((numbers) => {
	let i = 1000;
	numbers.forEach((number) => {
		if (number.target instanceof HTMLElement && number.target.dataset.countUp) {
			const num = Number(number.target.dataset.countUp.replace(/\s/g, ''));

			anime({
				targets: number.target,
				innerText: [0, num],
				round: 1,
				easing: 'cubicBezier(0.35, 0, 0.3, 1)',
				duration: () => {
					return i;
				},
				update: function (a) {
					const value = a.animations[0].currentValue;
					const formattedNumber = value
						.toString()
						.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
					if (number.target instanceof HTMLElement) {
						number.target.innerText = formattedNumber;
					}
				},
			});
		}
		i += 200;
	});
});

numbers.forEach((number) => {
	observer.observe(number);
});
