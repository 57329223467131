.wrap {
	--icon-size: 1.4rem;
	appearance: 'none';
	width: var(--icon-size);
	height: var(--icon-size);
	background-color: var(--edit-symbol-color, var(--color-blue));
	border-radius: 0.8rem;
}

.icon {
	width: var(--icon-size);
	height: var(--icon-size);
}
