.exampleList {
	display: flex;
	gap: 0.3rem;
	overflow-x: auto;
	scrollbar-width: none;
	-ms-overflow-style: none;
}

.exampleList::-webkit-scrollbar {
	display: none;
}

.exampleList {
	padding-top: 0.2rem;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	padding-bottom: 0.5rem;
	margin-left: -0.5rem;
	margin-right: -0.5rem;
}

/*@media (max-width: 575px) {
	.exampleList {
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: var(--color-light-blue);
		padding: var(--page-margin);
	}
}*/

.exampleButton {
	appearance: none;
	border: 0;
	padding: 0.5rem 0.6rem;
	width: 8.4rem;
	flex-shrink: 0;

	font-size: 0.8rem;
	text-align: center;
	cursor: pointer;

	color: var(--color-text);
	background: var(--color-background);
	border-radius: var(--card-border-radius);
	box-shadow: var(--shadow-medium);
}

.exampleButton:hover {
	background-color: var(--input-background-color--hover);
}

.exampleButton:hover:not(.exampleButtonSelected) {
	box-shadow: var(--shadow-medium), 0 0 0 1px var(--input-border-color);
}

.exampleButtonSelected {
	box-shadow: var(--shadow-medium), 0 0 0 3px var(--color-blue);
}

.lineClamp {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
}
