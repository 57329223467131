export default class CTA {
	element: HTMLElement;
	swishForm!: HTMLElement;
	footer?: HTMLElement;
	ctaObserver!: IntersectionObserver;
	footerObserver!: IntersectionObserver;

	isSwishFormVisible = false;
	isFooterVisible = false;

	constructor(cta: HTMLElement) {
		this.element = cta;
		this.swishForm =
			document.querySelector<HTMLFormElement>('[data-swish-form]')!;
		if (!this.swishForm) return;

		this.footer = document.querySelector<HTMLElement>('.footer') || undefined;

		const header = document.querySelector<HTMLElement>('[data-header]');
		let headerHeight = header ? window.getComputedStyle(header).height : '46px';

		const ctaHeight = window.getComputedStyle(this.element).height;

		this.ctaObserver = new IntersectionObserver(this.handleCtaObserver, {
			rootMargin: `${headerHeight} 0px ${ctaHeight} 0px`,
			threshold: 0.7,
		});

		this.footerObserver = new IntersectionObserver(this.handleFooterObserver, {
			rootMargin: '0px',
			threshold: 0.0,
		});

		const mobileMedia = window.matchMedia('(max-width: 575px)');
		mobileMedia.addEventListener('change', this.handleMediaChange);
		this.handleMediaChange(mobileMedia);
	}

	updateCtaState = () => {
		this.element.classList.toggle(
			'cta--hide',
			!this.isFooterVisible && this.isSwishFormVisible
		);
	};

	handleCtaObserver: IntersectionObserverCallback = (entries) => {
		this.isSwishFormVisible = entries.some(
			(entry) => entry.target === this.swishForm && entry.isIntersecting
		);
		this.updateCtaState();
	};

	handleFooterObserver: IntersectionObserverCallback = (entries) => {
		this.isFooterVisible = entries.some(
			(entry) => entry.target === this.footer && entry.isIntersecting
		);
		this.updateCtaState();
	};

	handleMediaChange = (event: MediaQueryList | MediaQueryListEvent) => {
		if (event.matches) {
			this.addObserver();
		} else {
			this.removeObserver();
		}
	};

	addObserver = () => {
		this.ctaObserver.observe(this.swishForm);
		if (this.footer) this.footerObserver.observe(this.footer);
	};

	removeObserver = () => {
		this.ctaObserver.unobserve(this.swishForm);
		if (this.footer) this.footerObserver.unobserve(this.footer);
	};
}
