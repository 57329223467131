import anime from 'animejs';

/**
 * Script to support the styles of the .overlapped-images block
 */

export default class OverlappedImages {
	constructor(el) {
		this.el = el;
		this.imageContainer = this.el.querySelector('.overlapped-image__container');
		this.imageEls = this.el.querySelectorAll('.overlapped-images__image'); // keep as NodeList for AnimeJS
		this.images = [
			...this.el.querySelectorAll('.overlapped-images__image img'),
		];
		this.animatedIn = false;

		if (this.isAllLoaded()) {
			this.animateIn();
		} else {
			this.images
				.filter((image) => !image.complete)
				.forEach((image) => {
					image.addEventListener('load', this.handleLoadEvent);
					image.addEventListener('error', this.handleLoadEvent);
				});
		}
	}

	isAllLoaded = () => {
		return this.images.every((image) => image.complete);
	};

	handleLoadEvent = () => {
		if (this.isAllLoaded()) {
			this.animateIn();
		}
	};

	animateIn = () => {
		this.animatedIn = true;

		const totalDuration = 800;

		anime({
			targets: this.imageContainer,
			opacity: 1,
			easing: 'linear',
			duration: totalDuration,
		});
		anime({
			targets: this.imageEls,
			opacity: 1,
			translateY: [15, 0],
			duration: totalDuration,
			easing: 'easeOutCubic',
			delay: (_el, i) => i * (totalDuration / 10),
		});
	};
}
