import Popover from './popover';

let n = 0;

export default class LinkTerms {
	constructor(el) {
		n++;

		this.el = el;

		this.terms = this.el.getAttribute('data-link-terms');
		this.finePrint = this.el.getAttribute('data-link-terms-fine-print');
		this.acceptLabel =
			this.el.getAttribute('data-link-terms-accept') ||
			'Jag accepterar villkoren';
		this.declineLabel =
			this.el.getAttribute('data-link-terms-decline') || 'Nej tack';

		if (this.isForm) {
			this.el.addEventListener('submit', this.handlePrevent);
		} else {
			this.el.addEventListener('click', this.handlePrevent);
		}

		const popoverEl = document.createElement('div');
		popoverEl.id = 'link-terms-popover-' + n;
		popoverEl.className = 'popover popover--wide';
		popoverEl.innerHTML = `
			<button class="popover__close" data-popover-close>Stäng</button>
			<div class="popover__box">
				<p>${this.terms}</p>
				${
					this.finePrint
						? `
					<div class="small-text"><p>${this.finePrint}</p></div>
				`
						: ''
				}
				<div class="popover__actions popover__actions--horizontal">
					<button class="button button--filled" data-link-terms-accept>${
						this.acceptLabel
					}</button>
					${
						this.declineLabel
							? `<button class="button" data-popover-close>${this.declineLabel}</button>`
							: ''
					}
				</div>
			</div>
		`;

		this.popoverEl = popoverEl;
		document.body.appendChild(popoverEl);
		this.popover = new Popover(this.popoverEl);

		const acceptEl = this.popoverEl.querySelector('[data-link-terms-accept]');
		if (acceptEl) {
			acceptEl.addEventListener('click', this.handleAccept);
		}
	}

	handlePrevent = (ev) => {
		ev.preventDefault();
		this.popover.open(this.el);
	};

	handleAccept = () => {
		this.popover.close();

		const href = this.el.getAttribute('href') ?? '';
		const link = document.createElement('a');
		link.href = href;
		link.download = href.replace(/^.*[\\\/]/, '');
		link.click();
	};
}
