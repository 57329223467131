import { h, FunctionalComponent as FC, JSX } from 'preact';
import { Image } from './types';
import styles from './image-list.module.css';

type ImageListProps = {
	images: Image[];
	name?: string;
	selected: string;
	setSelected: (selected: string, name?: string) => void;
};

const ImageList: FC<ImageListProps> = ({
	images,
	name,
	selected,
	setSelected,
}) => {
	return (
		<div className={styles.imageList}>
			{images.map((image, i) => {
				const highlight =
					(selected === undefined && i === 0) || image.value === selected;
				return (
					<button
						type="button"
						aria-pressed={image.value === selected}
						className={`${styles.imageButton} ${
							highlight ? styles.imageButtonSelected : ''
						}`}
						onClick={() => setSelected(image.value, name)}
					>
						<img src={image.src} alt="" className={styles.image} />
					</button>
				);
			})}
		</div>
	);
};

export default ImageList;
