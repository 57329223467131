let youTubeAPILoaded = false;
const loadYouTubeAPI = (callback: () => void) => {
	if (youTubeAPILoaded) {
		callback();
		return;
	}

	const tag = document.createElement('script');
	tag.src = 'https://www.youtube.com/iframe_api';

	const firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag);

	youTubeAPILoaded = true;

	(window as any).onYouTubeIframeAPIReady = () => {
		callback();
	};
};

const createVideo = (el: HTMLElement) => {
	const videoId = el.getAttribute('data-video');
	const button = el.querySelector('button');
	const placeholder = el.querySelector<HTMLElement>('[data-placeholder]');
	const videoTemplate = el.querySelector('template');

	if (!videoId) return;

	button?.addEventListener('click', () => {
		const videoFragment = videoTemplate?.content.cloneNode(true);
		if (!videoFragment) return;

		loadYouTubeAPI(() => {
			new YT.Player(videoId, {
				events: {
					onReady: (event) => {
						event.target.playVideo();
					},
				},
			});
		});

		el.appendChild(videoFragment);

		if (placeholder) {
			placeholder.style.transition = 'opacity 250ms linear';
			placeholder.style.opacity = '0';

			placeholder.addEventListener('transitionend', () => {
				placeholder.parentNode?.removeChild(placeholder);
			});
		}
	});
};

document
	.querySelectorAll<HTMLElement>('[data-video]')
	.forEach((video) => createVideo(video));
