import { h, FunctionalComponent as FC, JSX } from 'preact';
import { useState, useCallback, useRef, useEffect } from 'preact/hooks';
import { Settings } from './types';
import Edit from './edit';
import FrameOverlay from './frame-overlay';
import { useMediaQuery } from './hooks/use-media-query';
import getFieldValue from './utilities/field-value';

type ProductPreviewProps = {
	settings: Settings;
	editable?: boolean;
};

const ProductPreview: FC<ProductPreviewProps> = ({ settings, editable }) => {
	const { cardUrl, fields, strings } = settings;

	const element = useRef<HTMLDivElement>(null);
	const [fieldValues, setFieldValues] = useState<Record<string, string>>(
		prepareFieldValues(fields)
	);
	const [activeArea, setActiveArea] = useState<string>();

	const handleUpdate = useCallback((fieldValues: Record<string, string>) => {
		setFieldValues((prev) => ({ ...prev, ...fieldValues }));
	}, []);

	/**
	 * Store the field values in sessionStorage if this preview is editable
	 */
	useEffect(() => {
		if (!editable) return;
		sessionStorage.osFields = JSON.stringify(fieldValues);
	}, [editable, fieldValues]);

	/**
	 * Update the field values from sessionStorage if this preview is not editable
	 */
	useEffect(() => {
		if (editable) return;

		const id = window.setInterval(() => {
			const fields = sessionStorage.osFields;
			if (fields && fields !== JSON.stringify(fieldValues)) {
				setFieldValues(JSON.parse(fields));
			}
		}, 500);

		return () => {
			window.clearInterval(id);
		};
	}, [editable, fieldValues]);

	const focusFieldButton = useCallback(
		(id: string) => {
			const button = element.current?.querySelector<HTMLElement>(
				`[data-button-id="${id}"]`
			);

			button?.focus();
		},
		[element]
	);

	const handleClose = useCallback(() => {
		const cachedActiveArea = activeArea;
		setActiveArea(undefined);

		if (cachedActiveArea) {
			// Wait until the next frame to focus the button
			window.setTimeout(() => {
				focusFieldButton(cachedActiveArea);
			}, 1);
		}
	}, [activeArea, focusFieldButton]);

	const mobile = useMediaQuery('(max-width: 575px)');

	return (
		<div style={wrapperStyle(mobile)} ref={element}>
			<FrameOverlay
				src={cardUrl}
				height={1123}
				width={794}
				activeArea={activeArea}
				fields={fields}
				fieldValues={fieldValues}
				style={frameStyle}
				editable={editable}
				onClick={(id) => {
					const field = fields.find((f) => f.area === id);
					setActiveArea(field?.area);
				}}
				onCoverClick={handleClose}
			>
				{(fields) => (
					<Edit
						fields={fields}
						fieldValues={fieldValues}
						strings={strings}
						onUpdate={handleUpdate}
						onClose={handleClose}
						autoFocus
					/>
				)}
			</FrameOverlay>
			{editable &&
				fields.map((field) => (
					<input
						type="hidden"
						key={field.id}
						name={field.id}
						value={getFieldValue(field, fieldValues)}
						data-fake-validation={
							getFieldValue(field, fieldValues) === '' ? 'invalid' : ''
						}
					/>
				))}
		</div>
	);
};

export default ProductPreview;

const wrapperStyle = (mobile: boolean): JSX.CSSProperties => ({
	position: 'relative',
	overflow: 'hidden',
	padding: mobile ? 14 : 40,
	background: 'var(--color-light-gray)',
});

const frameStyle: JSX.CSSProperties = {
	boxShadow: 'var(--shadow-medium)',
};

const prepareFieldValues = (fields: Field[]): Record<string, string> => {
	const values: Record<string, string> = {};

	fields.forEach((field) => {
		const currentFieldValue = values[field.id];
		if (!currentFieldValue) {
			values[field.id] = field.defaultValue;
		}
	});

	return values;
};
