import anime from 'animejs';
import debounce from 'lodash/debounce';

type ValidatableElement =
	| HTMLInputElement
	| HTMLTextAreaElement
	| HTMLSelectElement;

/**
 * Extend browser form validation
 */

export default class FormValidation {
	el: HTMLFormElement;
	validationErrorEl?: HTMLElement;
	errorAnimation?: anime.AnimeInstance;

	errorMessages = {
		formInvalid:
			'Formuläret innehåller %i fel. Se över dina uppgifter och försök igen.',
	};

	constructor(el: HTMLFormElement) {
		this.el = el;

		try {
			const errorElSelector = this.el.getAttribute('data-validation-error');
			this.validationErrorEl = errorElSelector
				? document.querySelector(errorElSelector) || undefined
				: undefined;
		} catch (error) {
			console.warn(error);
		}

		this.hideError();

		const formInvalidMessage = this.el.getAttribute('data-form-invalid');
		if (formInvalidMessage) {
			this.errorMessages.formInvalid = formInvalidMessage;
		}

		this.el.addEventListener('change', this.handleChange, true);
		this.el.addEventListener('blur', this.handleBlur, true);
		this.el.addEventListener('invalid', debounce(this.handleInvalid), true);
		this.el.addEventListener('submit', this.handleSubmit, false);
		this.el.addEventListener('HideFormValidation', this.hideError);
	}

	handleChange = (event: Event) => {
		if (!(event.target instanceof Element)) return;

		/**
		 * Validate immediately when a checkbox changes instead of waiting for the
		 * blur event to happen.
		 */
		if (
			event.target.getAttribute('type') === 'checkbox' &&
			event.target.classList.contains('u-show-validation')
		) {
			this.handleInvalid();
		}
	};

	handleBlur = (event: Event) => {
		if (!(event.target instanceof Element)) return;

		if (event.target.classList.contains('u-show-validation')) {
			this.handleInvalid();
		}
	};

	handleInvalid = () => {
		const errorNames = Array.from(
			this.el.querySelectorAll<ValidatableElement>('[data-validate]')
		).reduce<string[]>((fieldNames, input) => {
			const isVisible = input.offsetParent !== null;

			if (
				!input.validity.valid &&
				!fieldNames.includes(input.name) &&
				isVisible && // only show errors for visible fields
				input.classList.contains('u-show-validation') // only show errors for fields that show their validation status
			) {
				fieldNames.push(input.name);
			}

			return fieldNames;
		}, []);

		const errors = errorNames.length;

		if (errors) {
			this.showError(this.getErrorMessage(errors));
		} else {
			this.hideError();
		}
	};

	handleSubmit = () => {
		this.hideError();
	};

	getErrorMessage = (numberOfErrors: number) => {
		return this.errorMessages.formInvalid.replace('%i', String(numberOfErrors));
	};

	showError = (message: string) => {
		if (!this.validationErrorEl) return;
		const prevMessage = this.validationErrorEl.textContent;

		this.validationErrorEl.textContent = message;
		this.validationErrorEl.style.display = 'block';

		if (prevMessage !== message) {
			return;
		}

		if (!this.errorAnimation) {
			this.errorAnimation = anime({
				targets: this.validationErrorEl,
				duration: 125,
				scaleX: 1.03,
				scaleY: 1.05,
				loop: 1,
				easing: 'easeOutQuart',
				direction: 'alternate',
			});
		} else {
			this.errorAnimation.restart();
		}
	};

	hideError = () => {
		if (!this.validationErrorEl) return;
		this.validationErrorEl.style.display = 'none';
	};
}
