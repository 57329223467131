import { MIRROR_VALUE_TRIGGER_EVENT } from './field-mirror-value';

const controlsAttribute = 'data-controls';
const parentActiveClassAttribute = 'data-parent-active-class';
const setSubmitLabelAttribute = 'data-submit-label';
const triggerScrollRequestAttribute = 'data-trigger-scroll-request';

const controlsEl = (input) => input.getAttribute(controlsAttribute) !== null;
const setsSubmitLabel = (input) => input.getAttribute(setSubmitLabelAttribute);
const highlightsParent = (input) =>
	input.getAttribute(parentActiveClassAttribute);
const triggerScrollRequest = (input) =>
	(input.type === 'radio' || input.type === 'checkbox') &&
	input.getAttribute(triggerScrollRequestAttribute) !== null;
const controlForLabel = (label) => {
	let control = document.getElementById(label.getAttribute('for'));

	if (!control) {
		control = label.parentElement.querySelector('input');
	}

	return control;
};

/**
 * Helper script to add functionality to radio-set components
 */

export default class RadioSet {
	constructor(el) {
		this.el = el;
		this.inputs = [
			...this.el.querySelectorAll(
				'input[type="radio"], input[type="checkbox"]'
			),
		];
		this.toggleLabels = [
			...this.el.querySelectorAll(
				'[data-unchecked-content][data-checked-content]'
			),
		];

		// Highlight checked
		if (this.inputs.some(highlightsParent)) {
			this.handleHighlightChecked();
			this.inputs.forEach((input) => {
				input.addEventListener('change', this.handleHighlightChecked);
				input.setAttribute('data-highlight-checked-inited', 'true');
			});
		}

		// Set the submit label
		if (this.inputs.some(setsSubmitLabel)) {
			const submitInput = this.getSubmitInput();
			this.defaultSubmitLabel = submitInput ? submitInput.value : undefined;
			this.setSubmitLabel();

			this.inputs.forEach((input) =>
				input.addEventListener('change', this.setSubmitLabel)
			);
		}

		if (this.inputs.some(controlsEl)) {
			this.inputs.filter(controlsEl).forEach((input) => {
				input.addEventListener('change', this.handleControls);
			});
			this.handleControls();

			/**
			 * This also needs to apply to controls that are dynamically inserted after
			 * this constructor, so run again in the next tick.
			 */
			setTimeout(this.handleControls, 1);
		}

		// Trigger custom event for ScrollableList
		if (this.inputs.some(triggerScrollRequest)) {
			this.inputs.filter(triggerScrollRequest).forEach((input) => {
				input.addEventListener('change', () => {
					if (input.checked) {
						const eventElement = input.closest('[data-radio-parent]') || input;

						eventElement.dispatchEvent(
							new CustomEvent('ScrollRequest', { bubbles: true })
						);
					}
				});
			});
		}

		if (this.toggleLabels.length) {
			this.toggleLabels.forEach((label) => {
				const control = controlForLabel(label);

				if (!control) {
					return;
				}

				control.addEventListener('change', this.handleToggleLabels);
			});

			this.handleToggleLabels();
		}

		// Other controls can force this to re-evaluate
		document.addEventListener('radio-set-reevaluate-controls', () => {
			this.handleControls(true);
		});

		if (document.querySelector('.woocommerce-checkout-payment')) {
			this.initWooCommerceWorkaround();
		}
	}

	iterateOverInputs = (fn) => {
		this.inputs.forEach((input) => {
			const optionEl = input.closest('[data-radio-parent]');
			if (optionEl) {
				fn(optionEl, input.checked, input);
			}
		});
	};

	handleHighlightChecked = () => {
		this.iterateOverInputs((optionEl, checked, input) => {
			const parentActiveClass = input.getAttribute(parentActiveClassAttribute);
			if (!parentActiveClass) {
				return;
			}

			if (checked) {
				optionEl.classList.add(parentActiveClass);
			} else {
				optionEl.classList.remove(parentActiveClass);
			}
		});
	};

	setSubmitLabel = () => {
		this.iterateOverInputs((_optionEl, checked, input) => {
			const submitLabel = input.getAttribute(setSubmitLabelAttribute);
			const submit = this.getSubmitInput();
			if (!submitLabel || !submit) {
				return;
			}

			if (checked) {
				submit.value = submitLabel;
			} else if (this.defaultSubmitLabel) {
				submit.value = this.defaultSubmitLabel;
			}
		});
	};

	/**
	 * Update the controls based on the current state of the radio set
	 *
	 * @param {boolean} skipDisable Do not disable the inputs if this parameter is true
	 */
	handleControls = (skipDisable = false) => {
		const controlInputs = this.inputs
			.map((input) => {
				const selector = input.getAttribute(controlsAttribute);
				if (!selector) return;

				const targets = [...document.querySelectorAll(selector)];

				return { input, targets };
			})
			.filter((value) => !!value);

		const isVisible = (element) => element.offsetParent !== null;

		const setVisible = (visible, { targets }) => {
			targets.forEach((target) => {
				target.style.display = visible ? '' : 'none';

				// Remove "required" attribute from elements that are not visible
				const inputs = [
					...target.querySelectorAll(
						'input[data-validate], textarea[data-validate], select[data-validate]'
					),
				];
				inputs.forEach((input) => {
					if (visible) {
						input.dispatchEvent(new Event(MIRROR_VALUE_TRIGGER_EVENT));
					}
					if (isVisible(input) && visible) {
						if (input.hasAttribute('data-required-if-visible')) {
							input.setAttribute('required', 'required');
						}
					} else {
						if (
							input.hasAttribute('required') &&
							!input.hasAttribute('data-required-if-visible')
						) {
							input.setAttribute('data-required-if-visible', true);
						}

						input.removeAttribute('required');
					}
				});

				// Disable form element that are not visible
				[...target.querySelectorAll('input, textarea, select')].forEach(
					(input) => {
						if (isVisible(input) && visible) {
							input.removeAttribute('disabled');
						} else {
							// Disabling can optionally be skipped
							if (skipDisable !== true) {
								input.setAttribute('disabled', 'disabled');
							}
						}
					}
				);
			});

			// Some other elements needs window.resize to fire to correctly scale
			// if they were not previously visible
			let resizeEvent;
			if (typeof Event === 'function') {
				resizeEvent = new Event('resize');
			} else {
				resizeEvent = document.createEvent('Event');
				resizeEvent.initEvent('resize', true, true);
			}
			window.dispatchEvent(resizeEvent);
			window.dispatchEvent(
				new Event('radio-set-controls-visible', { bubbles: true })
			);
		};

		controlInputs
			.filter(({ input }) => !input.checked)
			.forEach(setVisible.bind(this, false));

		controlInputs
			.filter(({ input }) => input.checked)
			.forEach(setVisible.bind(this, true));
	};

	handleToggleLabels = () => {
		this.toggleLabels.forEach((label) => {
			const control = controlForLabel(label);
			if (!control) {
				return;
			}

			const checked = control.checked;
			const uncheckedContent = label.getAttribute('data-unchecked-content');
			const checkedContent = label.getAttribute('data-checked-content');

			if (uncheckedContent && checkedContent) {
				label.innerText = checked ? checkedContent : uncheckedContent;
			}
		});
	};

	getSubmitInput = () => {
		const form = this.el.closest('form');
		if (!form) return null;

		return form.querySelector('input[type="submit"]');
	};

	/**
	 * WooCommerce can replace the inputs with html form the server, which causes
	 * event listeners to get detached, as well as references in this.el and this.inputs
	 * to go missing. This function works around these constraints by always querying the
	 * DOM instead of using cached values in `this`.
	 */

	initWooCommerceWorkaround = () => {
		const body = document.querySelector('body');

		// Only set this up once
		if (body.getAttribute('data-woocommerce-radio-set-workaround') !== null) {
			return;
		}
		body.setAttribute('data-woocommerce-radio-set-workaround', 'true');

		body.addEventListener('change', (ev) => {
			const shouldChangeActiveClass = ev.target.matches(
				`[data-radio-set] [data-radio-parent] [${parentActiveClassAttribute}]`
			);
			const isNotInitialized =
				ev.target.getAttribute('data-highlight-checked-inited') === null;

			if (shouldChangeActiveClass && isNotInitialized) {
				const radioset = ev.target.closest('[data-radio-set]');

				radioset.querySelectorAll('[data-radio-parent]').forEach((optionEl) => {
					const input = optionEl.querySelector('input');
					if (!input) return;
					const checked = input.checked;
					const parentActiveClass = input.getAttribute(
						parentActiveClassAttribute
					);
					if (!parentActiveClass) {
						return;
					}

					if (checked) {
						optionEl.classList.add(parentActiveClass);
					} else {
						optionEl.classList.remove(parentActiveClass);
					}
				});
			}
		});
	};
}
