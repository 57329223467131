import { pingForResponse, initiateRequest } from '../utilities/bankid';
import { slideShow } from '../utilities/animations';
import SubmitLock from './submit-lock';
import detectDeviceType from '../utilities/device-detection';

import qrPhone from '../../assets/qr-phone.svg';

export default class SwishSubmit {
	el: HTMLFormElement;
	action: string;

	submitLock: SubmitLock;

	conditionalElements: NodeListOf<HTMLElement>;
	takeover: HTMLDivElement;
	errorElement: HTMLElement | null;

	constructor(el: HTMLFormElement) {
		this.el = el;
		this.action = this.el.getAttribute('data-swish-submit') || '';

		this.submitLock = new SubmitLock(this.el, false);

		this.conditionalElements = this.el.querySelectorAll(
			'[data-swish-submit-devicetype]'
		);

		this.takeover = document.createElement('div');
		this.takeover.style.display = 'none';
		this.takeover.innerHTML = this.getTemplate();
		this.el.appendChild(this.takeover);

		this.errorElement = this.el.querySelector('#form-error');

		this.el.addEventListener('submit', this.handleSubmit);

		const deviceType = detectDeviceType();
		this.conditionalElements.forEach((el) => {
			const elDeviceType = el.getAttribute('data-swish-submit-devicetype');
			if (elDeviceType !== deviceType) {
				el.style.display = 'none';
				el.querySelectorAll('input').forEach((input) => {
					input.setAttribute('disabled', 'disabled');
				});
			}
		});

		if (this.shouldPollForConfirmation()) {
			this.initializePolling();
		}
	}

	handleSubmit = async (ev: SubmitEvent) => {
		if (this.errorElement) {
			this.errorElement.style.display = 'none';
			this.errorElement.textContent = '';
		}

		this.submitLock.lock();
		ev.preventDefault();

		const formData = new FormData(this.el);
		const fields: { [index: string]: FormDataEntryValue } = {};
		formData.forEach(function (value, key) {
			fields[key] = value;
		});

		const deviceType = detectDeviceType();
		fields.deviceType = deviceType;

		if (deviceType !== 'mobile') {
			this.takeover.style.display = 'block';
		}

		try {
			let cachedQr = '';
			const { requestId, link } = await initiateRequest(this.action, {
				method: 'post',
				body: JSON.stringify(fields),
			});

			if (link && deviceType === 'mobile') {
				window.location.href = link;
				setTimeout(() => {
					this.submitLock.unlock();
				}, 3000);
				return;
			}

			const res = await pingForResponse<any, any>(
				`${this.action}/${requestId}`,
				(res) => {
					this.takeover.style.display = 'block';

					if (cachedQr !== res.qr) {
						cachedQr = res.qr;
						this.takeover.innerHTML = this.getTemplate({
							qr: res.qr,
							link: res.link,
						});
					}
					if (res.link && deviceType === 'mobile') {
						window.location.href = res.link;
					}
				},
				5000,
				30000
			);

			if (res.errorMessage) {
				this.showErrorMessage(res.errorMessage);
			} else if (res.confirmationPage) {
				window.location.href = res.confirmationPage;
			}
		} catch (e) {
			if (e instanceof Error) {
				this.showErrorMessage(e.message);
			}
		}
	};

	getTemplate = ({ qr = '', link = '' } = {}) => {
		const deviceType = detectDeviceType();
		const mobileAndLink = deviceType === 'mobile' && link;

		return `
		<div class="bankid-sign-takeover">
			<div class="bankid-sign-takeover__block waiting-for-signature">
				<img
					src="${qrPhone}"
					alt=""
					class="waiting-for-signature__symbol"
				/>
				<p class="waiting-for-signature__message ${
					qr ? 'waiting-for-signature__message--qr' : ''
				}">
				${
					qr && !mobileAndLink
						? 'Öppna Swish och scanna denna kod:'
						: 'Öppna Swish och fortsätt i appen.'
				}
				</p>
				${
					qr && !mobileAndLink
						? `<img src="${qr}" alt="" class="waiting-for-signature__qr">`
						: ''
				}
				${
					mobileAndLink
						? `<a href=${link} class="button waiting-for-signature__button">
							Öppna Swish-appen
						</a>`
						: ''
				}
				<p class="waiting-for-signature__loading loading"><span class="loading__label">Väntar…</span></p>
			</div>
		</div>
	`;
	};

	getPollingTemplate = () => {
		return `
		<div class="bankid-sign-takeover">
			<div class="bankid-sign-takeover__block waiting-for-signature">
				<img
					src="${qrPhone}"
					alt=""
					class="waiting-for-signature__symbol"
				/>
				<p class="waiting-for-signature__loading loading"><span class="loading__label">Bekräftar transaktion…</span></p>
			</div>
		</div>
	`;
	};

	shouldPollForConfirmation = (): boolean => {
		return this.getSearchParams() !== null;
	};

	getSearchParams = (): PollingSearchParams | null => {
		const paramsString = window.location.search;
		const params = new URLSearchParams(paramsString);

		if (params.has('swishId')) {
			return {
				amount: params.get('amount') || undefined,
				phone: params.get('phone') || undefined,
				swishId: params.get('swishId')!,
			};
		}

		return null;
	};

	initializePolling = async () => {
		const params = this.getSearchParams();
		if (params === null) return;

		this.el.scrollIntoView({ behavior: 'instant' });

		try {
			const phoneInput = this.el.querySelector('[name="phone"]');
			let amountRadio = this.el.querySelector(
				`input[type="radio"][name="amount"][value="${params.amount || ''}"]`
			);
			const customAmountInput = this.el.querySelector(
				'input[type="number"][name="amount"]'
			);
			const consentCheckbox = this.el.querySelector(
				'input[type="checkbox"][name="consent"]'
			);

			if (!amountRadio) {
				amountRadio = this.el.querySelector(
					`input[type="radio"][name="amount"][value="custom"]`
				);
				customAmountInput?.setAttribute('value', params.amount || '');
			}

			phoneInput?.setAttribute('value', params.phone || '');
			amountRadio?.setAttribute('checked', 'checked');
			consentCheckbox?.setAttribute('checked', 'checked');

			phoneInput?.dispatchEvent(new Event('change'));
			amountRadio?.dispatchEvent(new Event('change'));
			customAmountInput?.dispatchEvent(new Event('change'));

			this.takeover.style.display = 'block';
			this.takeover.innerHTML = this.getPollingTemplate();
		} catch (error) {
			console.warn(error);
		}

		let res: any = null;
		try {
			res = await pingForResponse<any, any>(
				`${this.action}/${params.swishId}`,
				(res) => {
					this.takeover.style.display = 'block';
					this.takeover.innerHTML = this.getPollingTemplate();
				},
				2500
			);
		} catch (error) {
			if (error instanceof Error && this.errorElement && error.message) {
				this.showErrorMessage(error.message);
			}
		}

		if (res.confirmationPage) {
			window.location.href = res.confirmationPage;
		}
	};

	showErrorMessage = (message: string) => {
		if (this.errorElement) {
			this.errorElement.textContent = message;
			slideShow(this.errorElement);
		}

		this.takeover.style.display = 'none';
		this.submitLock.unlock();
	};
}

type PollingSearchParams = {
	amount?: string;
	phone?: string;
	swishId: string;
};
