.wrapper {
	position: relative;
	background-color: #fff;
}

.button {
	position: absolute;
	z-index: 2;
	background-color: rgba(255, 0, 0, 0);
	border: 0;
	min-width: 5;
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	transition: opacity 300ms linear;
}

.buttonHighlight {
	border-radius: var(--input-border-radius);
	outline: 1px solid var(--input-border-color);
	outline-offset: 2px;
	box-shadow: 0 0 0 3px rgba(255, 255, 255, 0);
	background-color: rgba(255, 255, 255, 0);
	transition-property: color, box-shadow, outline-color, border-color, opacity;
	transition-duration: 100ms, 100ms, 100ms, 100ms, 300ms;
	mix-blend-mode: multiply;
}

.buttonHighlight,
.buttonHighlight.buttonHighlight:focus /* extra specificity needed */ {
	outline: 1px solid var(--input-border-color);
}

.buttonHighlight:hover {
	outline-color: var(--input-border-color--hover);
	background-color: var(--input-background-color--hover);
}

.buttonFilled {
	background-color: transparent;
}

.buttonFilled:not(:hover) {
	outline-color: transparent;
}

.buttonError {
	outline-color: var(--input-border-color--invalid);
}

.buttonError:hover {
	outline-color: var(--input-border-color--invalid-hover);
	background-color: var(--input-background-color--invalid-hover);
}

.field {
	width: max-content;
	animation: overlayFormIn 350ms cubic-bezier(0.12, 0.78, 0, 1);
	max-width: 100%;
}

.cover {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100%;
	height: 100%;
	background-color: rgba(252, 252, 252, 0.8);

	padding: var(--page-margin);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	animation: overlayCoverIn 350ms cubic-bezier(0.12, 0.78, 0, 1);
	backdrop-filter: blur(0.6rem);
}

.editSymbol {
	transform: translate(120%, 0);
	transition: background-color 100ms linear;
}

button:hover .editSymbol {
	--edit-symbol-color: #164dad;
}

@keyframes overlayFormIn {
	from {
		opacity: 0;
		transform: scale(0.9);
	}

	to {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes overlayCoverIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
