import {
	Field,
	MessageSetEditable,
	MessageSetFrameId,
	MessageSetMobile,
	MessageUpdateContent,
	MessageUpdateRect,
	MessageUpdateRects,
} from './types';

export const isField = (field: unknown): field is Field => {
	return (
		typeof field === 'object' &&
		field !== null &&
		'id' in field &&
		typeof field.id === 'string' &&
		'name' in field &&
		typeof field.name === 'string' &&
		'type' in field &&
		typeof field.type === 'string' &&
		'defaultValue' in field &&
		typeof field.defaultValue === 'string' &&
		(!('exampleValues' in field) ||
			('exampleValues' in field &&
				typeof field.exampleValues === 'undefined') ||
			('exampleValues' in field && Array.isArray(field.exampleValues)))
	);
};

export const isMessageUpdateRect = (
	message: unknown
): message is MessageUpdateRect => {
	return (
		typeof message === 'object' &&
		message !== null &&
		'type' in message &&
		message.type === 'update-rect' &&
		'id' in message &&
		typeof message.id === 'string' &&
		'frameId' in message &&
		typeof message.frameId === 'string'
	);
};

export const isMessageUpdateContent = (
	message: unknown
): message is MessageUpdateContent => {
	return (
		typeof message === 'object' &&
		message !== null &&
		'type' in message &&
		message.type === 'update-content'
	);
};
export const isMessageUpdateRects = (
	message: unknown
): message is MessageUpdateRects => {
	return (
		typeof message === 'object' &&
		message !== null &&
		'type' in message &&
		message.type === 'update-rects'
	);
};

export const isMessageSetMobile = (
	message: unknown
): message is MessageSetMobile => {
	return (
		typeof message === 'object' &&
		message !== null &&
		'type' in message &&
		message.type === 'set-mobile' &&
		'mobile' in message &&
		typeof message.mobile === 'boolean'
	);
};

export const isMessageSetField = (
	message: unknown
): message is MessageSetEditable => {
	return (
		typeof message === 'object' &&
		message !== null &&
		'type' in message &&
		message.type === 'set-editable' &&
		'editable' in message &&
		typeof message.editable === 'boolean'
	);
};

export const isMessageSetFrameId = (
	message: unknown
): message is MessageSetFrameId => {
	return (
		typeof message === 'object' &&
		message !== null &&
		'type' in message &&
		message.type === 'set-frame-id' &&
		'frameId' in message &&
		typeof message.frameId === 'string'
	);
};
