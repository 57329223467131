export default function makeId(content: string, prefix = '') {
	return (
		prefix +
		content
			.toLowerCase()
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
			.split(' ')
			.slice(0, 6)
			.join('-')
			.replace(/[^a-z\-]/g, '')
	);
}
