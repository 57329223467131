export default class ScrollableList {
	el: HTMLElement;
	container: HTMLElement | null;
	children: HTMLElement[];
	nextButtons: NodeListOf<HTMLElement>;
	prevButtons: NodeListOf<HTMLElement>;

	constructor(el: HTMLElement) {
		this.el = el;

		this.container = el.querySelector('[data-scroll-container]');
		this.children = Array.from(
			this.container?.querySelectorAll(':scope > *') || []
		);

		this.nextButtons = el.querySelectorAll('[data-scroll-action="next"]');
		this.prevButtons = el.querySelectorAll('[data-scroll-action="prev"]');

		if (this.container) {
			new DragToScroll(this.container, this);
		}

		el.addEventListener(
			'ScrollRequest',
			this.handleScrollRequest as EventListener,
			true
		);

		this.nextButtons.forEach((button) =>
			button.addEventListener('click', this.handleNext)
		);

		this.prevButtons.forEach((button) =>
			button.addEventListener('click', this.handlePrev)
		);

		window.addEventListener('resize', this.handleScroll);
		this.container?.addEventListener('scroll', this.handleScroll);
		this.handleScroll();
	}

	scrollBy = (left: number) => {
		const closestElement = this.getClosestElement(left > 0 ? 'left' : 'right');
		const next = left > 0 ? closestElement?.nextElementSibling : closestElement;

		if (next instanceof HTMLElement) {
			this.scrollTo(next);
		}
	};

	handleNext = () => {
		this.scrollBy(1);
	};

	handlePrev = () => {
		this.scrollBy(-1);
	};

	handleScroll = () => {
		this.evaluateActiveButtons();
		this.evaluateVisibleChildren();
	};

	handleScrollRequest = (event: CustomEvent) => {
		if (!event.target) {
			return;
		}

		if (
			this.el.contains(event.target as Node) &&
			!this.elementIsVisible(event.target as HTMLElement)
		) {
			this.scrollTo(event.target as HTMLElement);
		}
	};

	getItemWidth = () => {
		return this.children[0]?.getBoundingClientRect().width;
	};

	getGutterWidth = () => {
		if (this.children.length < 2) return 0;

		const styles = window.getComputedStyle(this.children[0]);
		return parseInt(styles.marginRight, 10);
	};

	evaluateActiveButtons = () => {
		if (this.container === null) return;

		const margin = 2;
		const nextDisabled =
			this.container.scrollLeft + this.container.clientWidth >=
			this.container?.scrollWidth - margin;
		const prevDisabled = this.container?.scrollLeft <= margin;

		const enableButton = (button: HTMLElement) => {
			button.removeAttribute('disabled');
			button.style.pointerEvents = 'auto';
		};

		const disableButton = (button: HTMLElement) => {
			button.setAttribute('disabled', 'disabled');
			button.style.pointerEvents = 'none';
		};

		this.nextButtons.forEach((button) => {
			if (nextDisabled) {
				disableButton(button);
			} else {
				enableButton(button);
			}
		});

		this.prevButtons.forEach((button) => {
			if (prevDisabled) {
				disableButton(button);
			} else {
				enableButton(button);
			}
		});
	};

	evaluateVisibleChildren = () => {
		const elRect = this.el.getBoundingClientRect();

		this.children.forEach((child) => {
			const visible = this.elementIsVisible(child, elRect);

			child.classList.toggle('is-outside', !visible);
		});
	};

	elementIsVisible = (element: HTMLElement, comparisonRect?: DOMRect) => {
		comparisonRect = comparisonRect || this.el.getBoundingClientRect();

		const rect = element.getBoundingClientRect();

		return (
			Math.round(rect.left + 3) >= Math.round(comparisonRect.left) &&
			Math.round(rect.right - 3) <= Math.round(comparisonRect.right)
		);
	};

	getClosestElement = (direction: 'left' | 'right' = 'left') => {
		const parentRect = this.el.getBoundingClientRect();

		let elements = Array.from(
			this.el.querySelectorAll<HTMLElement>(':scope > * > *')
		);

		if (direction === 'right') {
			elements = [...elements].reverse();
		}

		return elements.find((child) => {
			const rect = child.getBoundingClientRect();
			if (direction === 'right') {
				return rect.left < parentRect.left && rect.left + 10 > -rect.width;
			}
			return rect.left + 10 > parentRect.left;
		});
	};

	scrollTo = (element: HTMLElement) => {
		let elementLeft = element.offsetLeft;

		// 575px corresponds to media query in list.css
		if (window.innerWidth <= 575) {
			elementLeft =
				element.offsetLeft -
				(window.innerWidth - element.offsetWidth - this.getGutterWidth()) / 2;
		}

		this.container?.scrollTo({ left: elementLeft, behavior: 'smooth' });
	};
}

class DragToScroll {
	isDragging = false;
	startX = 0;
	lastX = 0;
	deltaX = 0;
	scrollSnapTimeout = 0;
	prevPointerEventTarget: Element | undefined;

	constructor(
		public el: HTMLElement,
		public list: ScrollableList
	) {
		this.el.addEventListener('mousedown', this.handleMouseDown);
		this.el.addEventListener('mousemove', this.handleMouseMove);
		this.el.addEventListener('mouseup', this.handleMouseUp);
		this.el.addEventListener('click', this.handleMouseClick);
		this.el.addEventListener('pointerdown', this.handlePointerDown);
		this.el.addEventListener('pointerup', this.handlePointerUp);
	}

	handleMouseDown = (event: MouseEvent) => {
		/**
		 * Do not start dragging if meta key is held down, or if a
		 * mouse button other than the primary one is used.
		 */
		if (event.metaKey || event.button !== 0) return;

		event.preventDefault();
		clearTimeout(this.scrollSnapTimeout);

		this.isDragging = true;
		this.startX = event.clientX;
		this.lastX = event.clientX;

		this.el.style.scrollSnapType = 'none';
	};

	handleMouseMove = (event: MouseEvent) => {
		if (!this.isDragging) return;

		this.deltaX = event.clientX - this.lastX;
		this.lastX = event.clientX;

		this.el.scrollLeft -= this.deltaX;
	};

	handleMouseUp = () => {
		this.isDragging = false;

		/**
		 * Find the position of the closest element and scroll to it.
		 */
		const closestElement = this.list.getClosestElement(
			this.deltaX > 0 ? 'right' : 'left'
		);

		if (!closestElement) return;

		this.list.scrollTo(closestElement);

		/**
		 * Re-apply scroll snap after a few seconds (hopefully after smooth
		 * scrolling has finished).
		 */
		this.scrollSnapTimeout = window.setTimeout(() => {
			this.el.style.scrollSnapType = 'x mandatory';
		}, 2000);
	};

	handleMouseClick = (event: MouseEvent) => {
		/**
		 * Prevent clicks if the mouse has moved more than a few pixels since
		 * mousedown.
		 */
		if (Math.abs(this.startX - this.lastX) > 10) {
			event.preventDefault();
		}
	};

	handlePointerDown = (event: PointerEvent) => {
		if (!(event.target instanceof Element)) return;
		/**
		 * Capture the pointer so dragging outside the window still works.
		 */
		event.target.setPointerCapture(event.pointerId);
		this.prevPointerEventTarget = event.target;
	};

	handlePointerUp = (event: PointerEvent) => {
		this.prevPointerEventTarget?.releasePointerCapture(event.pointerId);
		this.prevPointerEventTarget = undefined;
	};
}
