/**
 * Fetch that throws on anything that's not 200, and parses
 * json responses.
 */

export default async function fetchJson(
	url: string,
	options: RequestInit = {}
) {
	return fetch(url, options).then(checkStatus).then(parseJson);
}

class ErrorWithResponse extends Error {
	response?: Response;
}

const checkStatus = (res: Response) => {
	if (res.status >= 200 && res.status < 500) {
		return res;
	} else {
		const error = new ErrorWithResponse(res.statusText);
		error.response = res;
		throw error;
	}
};

const parseJson = (res: Response) => res.json();
