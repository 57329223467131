/**
 * BankID signature functions
 */

import fetchJson from './fetchJson';

/**
 * The initiateRequest function returns the request id to use to
 * continue pinging.
 */

export async function initiateRequest(
	url: string,
	options: RequestInit
): Promise<{ requestId: string; link?: string }> {
	const res = await fetchJson(url, options);
	if (res.status === 'ok') {
		const { requestId, link } = res.payload;
		return { requestId, link };
	} else if (res.status === 'error' && res.errorMessage) {
		throw new Error(res.errorMessage);
	}
}

/**
 * The pingForResponse function returns a promise that resolves
 * when the API returns an 'ok' response. Until then it silently
 * pings the API.
 *
 * If anything needs to happen based on the 'pending' responses,
 * the 'pendingPingCallback' can be used for this purpose.
 */

let pingTimeoutId: number | null = null;

export function pingForResponse<T, K>(
	url: string,
	pendingPingCallback: (res: T) => void,
	frequency = 1500,
	delayAfterFirst?: number
): Promise<K> {
	let firstRequest = true;

	return new Promise(async function ping(resolve, reject) {
		const res = await fetchJson(url);
		if (pingTimeoutId) {
			window.clearTimeout(pingTimeoutId);
		}
		switch (res.status) {
			case 'pending':
				if (typeof pendingPingCallback === 'function') {
					pendingPingCallback(res);
				}

				const delay =
					firstRequest && delayAfterFirst ? delayAfterFirst : frequency;

				pingTimeoutId = window.setTimeout(
					ping.bind(null, resolve, reject),
					delay
				);

				firstRequest = false;
				break;
			case 'ok':
				return resolve(res.payload);
			case 'error':
				return reject(new Error(res.errorMessage));
		}
	});
}
