type ConditionString =
	| '==='
	| '=='
	| '!=='
	| '!='
	| '>'
	| '>='
	| '<'
	| '<='
	| 'in'
	| 'not in';

interface Conditions {
	[input: string]: [ConditionString, string | number];
}

const operations: { [K in ConditionString]: (a: any, b: any) => boolean } = {
	'===': (a, b) => a === b,
	'==': (a, b) => a == b,
	'!==': (a, b) => a !== b,
	'!=': (a, b) => a != b,
	'>': (a, b) => a > b,
	'>=': (a, b) => a >= b,
	'<': (a, b) => a < b,
	'<=': (a, b) => a <= b,
	in: (a, b) => b.includes(a),
	'not in': (a, b) => !b.includes(a),
};

type ElementAction = [HTMLElement, typeof DISABLE | typeof ENABLE];

type FormControl =
	| HTMLInputElement
	| HTMLSelectElement
	| HTMLTextAreaElement
	| HTMLButtonElement;

const CONTROL_SELECTOR = 'input, select, textarea, button';
const ENABLE = Symbol('enable');
const DISABLE = Symbol('disable');

const normalizeValue = (value: FormDataEntryValue) =>
	typeof value === 'string' && value.match(/^\d+$/) ? parseInt(value) : value;

const normalizeConditions = (conditions: Object) =>
	Object.entries(conditions).reduce((acc, [input, rawCondition]) => {
		const condition =
			typeof rawCondition === 'string'
				? ['in', rawCondition.split(/\s*,\s*/).map(normalizeValue)]
				: rawCondition;

		return {
			...acc,
			[input]: condition,
		};
	}, {});

/**
 * Control element disabled state based on form values
 */
export default class FormConditions {
	form: HTMLFormElement;
	disableElements: NodeListOf<HTMLElement>;
	enableElements: NodeListOf<HTMLElement>;

	constructor(form: HTMLFormElement) {
		this.form = form;
		this.disableElements = form.querySelectorAll('[data-disable-when]');
		this.enableElements = form.querySelectorAll('[data-enable-when]');

		if (this.disableElements.length || this.enableElements.length) {
			this.form.addEventListener('change', () => this.update(), true);
		}

		this.update();
	}

	update() {
		const formData = new FormData(this.form);

		const elementAndAction: ElementAction[] = [
			...this.getActionsForElements(this.disableElements, DISABLE, formData),
			...this.getActionsForElements(this.enableElements, ENABLE, formData),
		];

		elementAndAction.forEach(([el, action]) => {
			const enabledClass = el.getAttribute('data-enabled-class');
			const disabledClass = el.getAttribute('data-disabled-class');
			let controls: FormControl[] = [];

			if (el.matches(CONTROL_SELECTOR)) {
				controls = [el as FormControl];
			} else {
				controls = Array.from(el.querySelectorAll(CONTROL_SELECTOR));
			}

			if (enabledClass) {
				el.classList.toggle(enabledClass, action === ENABLE);
			}

			if (disabledClass) {
				el.classList.toggle(disabledClass, action === DISABLE);
			}

			controls.forEach((control) => {
				control.disabled = action === DISABLE;

				if (
					action === DISABLE &&
					control instanceof HTMLInputElement &&
					(control.type === 'checkbox' || control.type === 'radio')
				) {
					control.checked = false;
				}
			});
		});
	}

	getActionsForElements(
		elements: NodeListOf<HTMLElement>,
		matchAction: typeof ENABLE | typeof DISABLE,
		formData: FormData
	): ElementAction[] {
		const oppositeAction = matchAction === ENABLE ? DISABLE : ENABLE;

		return Array.from(elements).reduce<ElementAction[]>(
			(elementAndAction, el) => {
				const conditions: Conditions = normalizeConditions(
					JSON.parse(el.getAttribute('data-enable-when') || '{}')
				);

				const conditionsMatch = Object.entries(conditions).some(
					([input, [condition, value]]) => {
						const formValue = formData.get(input);

						if (formValue === null) {
							return false;
						}

						const normalizedFormValue = normalizeValue(formValue);

						return operations[condition](normalizedFormValue, value);
					}
				);

				return [
					...elementAndAction,
					[el, conditionsMatch ? matchAction : oppositeAction],
				];
			},
			[]
		);
	}
}
