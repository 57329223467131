const validClass = 'amount--valid';
const invalidClass = 'amount--invalid';

export default class Amount {
	checkValidity = false;

	el: HTMLElement;
	input?: HTMLInputElement;

	constructor(el: HTMLElement) {
		this.el = el;
		this.input = el.querySelector('input') || undefined;

		if (this.input) {
			this.input.addEventListener('keyup', this.handleKeyUp);
			this.input.addEventListener('blur', this.handleBlur);
			this.input.addEventListener('invalid', this.handleBlur);
		}
	}

	handleKeyUp = () => {
		if (!this.checkValidity) {
			return;
		}

		this.validate();
	};

	handleBlur = () => {
		this.checkValidity = true;
		this.validate();
	};

	validate = () => {
		if (this.input?.checkValidity()) {
			this.setValid();
		} else {
			this.setInvalid();
		}
	};

	setValid = () => {
		this.el.classList.add(validClass);
		this.el.classList.remove(invalidClass);
	};

	setInvalid = () => {
		this.el.classList.add(invalidClass);
		this.el.classList.remove(validClass);
	};
}
