import { h, FunctionComponent as FC, JSX, Ref } from 'preact';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
import { isMessageUpdateRect } from './typeguards';
import {
	MessageSetEditable,
	MessageSetFrameId,
	MessageSetMobile,
	MessageUpdateContent,
	MessageUpdateRect,
} from './types';
import { MessageUpdateRects } from './types';
import { useMediaQuery } from './hooks/use-media-query';

type FrameProps = {
	src: string;
	width: number;
	height: number;
	scaleFactor: number;
	fieldValues: Record<string, string>;
	innerRef?: Ref<HTMLDivElement>;
	editable: boolean;
	onRectsUpdate?: (rects: MessageUpdateRect) => void;
};

const Frame: FC<FrameProps> = ({
	src,
	width,
	height,
	scaleFactor,
	fieldValues,
	innerRef,
	editable,
	onRectsUpdate,
}) => {
	const frameId = useMemo(() => createFrameId(), []);
	const iframe = useRef<HTMLIFrameElement>(null);
	const [frameHasLoaded, setFrameHasLoaded] = useState(false);

	useEffect(() => {
		const handleMessage = (ev: MessageEvent) => {
			if (
				isMessageUpdateRect(ev.data) &&
				onRectsUpdate &&
				ev.data.frameId === frameId
			) {
				onRectsUpdate(ev.data);
			}
		};

		window.addEventListener('message', handleMessage);

		return () => {
			window.removeEventListener('message', handleMessage);
		};
	}, [frameHasLoaded]);

	/**
	 * Set ID inside iframe
	 * */
	useEffect(() => {
		if (!iframe.current) return;

		const message: MessageSetFrameId = {
			type: 'set-frame-id',
			frameId,
		};
		iframe.current.contentWindow?.postMessage(message, '*');
	}, [frameHasLoaded, frameId]);

	/**
	 * Set editable class inside iframe
	 * */
	useEffect(() => {
		if (!iframe.current) return;

		const message: MessageSetEditable = {
			type: 'set-editable',
			editable,
		};
		iframe.current.contentWindow?.postMessage(message, '*');
	}, [frameHasLoaded, editable]);

	/**
	 * Set mobile class inside iframe
	 * */
	const isMobile = useMediaQuery('(max-width: 575px)');
	useEffect(() => {
		if (!iframe.current) return;

		const message: MessageSetMobile = {
			type: 'set-mobile',
			mobile: isMobile,
		};
		iframe.current.contentWindow?.postMessage(message, '*');
	}, [frameHasLoaded, isMobile]);

	/**
	 * Post message to iframe to set field values
	 */
	useEffect(() => {
		if (!iframe.current) return;

		const message: MessageUpdateContent = {
			type: 'update-content',
			fieldValues,
		};

		iframe.current.contentWindow?.postMessage(message, '*');
	}, [frameHasLoaded, fieldValues]);

	/**
	 * Update areas when steps change
	 */
	useEffect(() => {
		const reevalRects = () => {
			window.setTimeout(() => {
				if (!iframe.current) return;

				const message: MessageUpdateRects = {
					type: 'update-rects',
				};

				iframe.current.contentWindow?.postMessage(message, '*');
			}, 375); // delay necessary to wait for steps form transition to end
		};

		document.addEventListener('step-activate', reevalRects);

		return () => {
			document.removeEventListener('step-activate', reevalRects);
		};
	}, []);

	return (
		<div
			ref={innerRef}
			style={{
				...iframeStyle,
				width,
				height,
			}}
		>
			<iframe
				id={frameId}
				src={src}
				frameBorder="0"
				width={width}
				height={height}
				style={{
					transform: `scale(${scaleFactor})`,
					transformOrigin: '0 0',
				}}
				ref={iframe}
				onLoad={() => setFrameHasLoaded(true)}
			/>
		</div>
	);
};

export default Frame;

const iframeStyle: JSX.CSSProperties = {
	position: 'absolute',
	top: 0,
	left: 0,
};

let id = 0;
const createFrameId = () => `preview-${++id}`;
