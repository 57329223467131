import { createPopper } from '@popperjs/core';

export default class Tooltip {
	showClassName = 'tooltip--show';
	openedWithClickClassName = 'tooltip--opened-with-click';
	timerId?: number;

	el: HTMLElement;
	button: HTMLButtonElement;
	tooltip: HTMLElement;
	popperInstance?: ReturnType<typeof createPopper>;
	clickedToShow = false;

	constructor(el: HTMLElement) {
		this.el = el;
		this.button = el.querySelector('button')!;
		this.tooltip = el.querySelector<HTMLElement>('[role="tooltip"]')!;

		this.popperInstance = this.#createPopper();

		this.button.addEventListener('click', this.#handleClick);

		this.button.addEventListener('mouseenter', this.show);
		this.el.addEventListener('mouseleave', this.#handleMouseLeave);
		this.tooltip.addEventListener('mouseenter', this.#handleMouseEnterTooltip);

		document.addEventListener('click', this.#handleDocumentClick);

		window.addEventListener('keydown', this.#handleWindowKeyDown);
		window.addEventListener('focusin', this.#handleWindowFocusIn);
	}

	/**
	 * Show the tooltip
	 */
	show = () => {
		clearTimeout(this.timerId);
		this.el.classList.add(this.showClassName);
		this.popperInstance?.update();
	};

	/**
	 * Hide the tooltip
	 */
	hide = () => {
		if (this.#tooltipSelected()) return;

		this.el.classList.remove(this.showClassName);
		this.el.classList.remove(this.openedWithClickClassName);
	};

	/**
	 * Hide the tooltip after a delay
	 */
	hideDelayed = (delay = 1000) => {
		clearTimeout(this.timerId);
		this.timerId = window.setTimeout(this.hide, delay);
	};

	/**
	 * Toggle the tooltip
	 */
	toggle = () => {
		const visible = this.el.classList.toggle(this.showClassName);
		if (visible) this.popperInstance?.update();
	};

	#handleClick = (event: MouseEvent) => {
		event.preventDefault();
		this.show();
		this.el.classList.add(this.openedWithClickClassName);
		this.clickedToShow = true;
	};

	#handleMouseLeave = () => {
		if (this.clickedToShow === true) return;
		this.hideDelayed(250);
	};

	#handleMouseEnterTooltip = () => {
		clearTimeout(this.timerId);
	};

	#handleDocumentClick = (event: MouseEvent) => {
		if (!(event.target instanceof Element)) return;

		if (event.target !== this.el && !this.el.contains(event.target)) {
			this.hide();
			this.clickedToShow = false;
		}
	};

	#handleWindowKeyDown = (event: KeyboardEvent) => {
		if (event.key === 'Escape') {
			this.hide();
			this.clickedToShow = false;
		}
	};

	#handleWindowFocusIn = (event: FocusEvent) => {
		if (!(event.target instanceof Element)) return;

		if (!this.el.contains(event.target)) {
			this.hide();
		}
	};

	#createArrow = () => {
		const arrow = document.createElement('span');
		arrow.classList.add('tooltip__arrow');
		this.tooltip.insertBefore(arrow, this.tooltip.firstChild);
		return arrow;
	};

	#createPopper = () => {
		return createPopper(this.button, this.tooltip, {
			placement: 'top',
			modifiers: [
				{
					name: 'offset',
					options: {
						offset: [3, 3],
					},
				},
				{
					name: 'arrow',
					options: {
						element: this.#createArrow(),
						padding: 14,
					},
				},
				{
					name: 'flip',
					options: {
						padding: { top: 84, left: 10, right: 10, bottom: 10 },
					},
				},
				{
					name: 'preventOverflow',
					options: {
						padding: 10,
					},
				},
			],
		});
	};

	#tooltipSelected = () => {
		const selection = window.getSelection();
		if (!selection || selection.rangeCount <= 0 || selection.isCollapsed)
			return false;

		const range = selection.getRangeAt(0);
		const parent = range.commonAncestorContainer;

		return this.tooltip.contains(parent);
	};
}
