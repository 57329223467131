export function filesAboveMaxSize(
	files: FileList,
	maxFileSize: number | string
) {
	const max = Number(maxFileSize);
	if (!maxFileSize || isNaN(max)) {
		throw new Error(
			'Provided maxFileSize is not a valid number. It needs to be specificed as a number of bytes.'
		);
	}

	return Array.from(files).some((file) => file.size > max);
}

export function formatFileSize(bytes: number | string) {
	bytes = Number(bytes);
	const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	let cursor = 0;
	let value = bytes;
	while (value > 1000 && units[cursor + 1]) {
		value /= 1000;
		cursor += 1;
	}
	const decimals = Math.max(0, cursor - 1);
	const roundedValue = +value.toFixed(decimals);
	return `${roundedValue} ${units[cursor]}`;
}
