export default class BusinessPackages {
	el: HTMLElement;
	input?: HTMLInputElement;
	submit?: HTMLInputElement;
	elements?: HTMLElement[];

	isShowingValidation = false;

	constructor(el: HTMLElement) {
		this.el = el;
		if (!el) {
			return;
		}

		this.input =
			el.querySelector<HTMLInputElement>('[data-package-input]') || undefined;
		this.submit =
			el.querySelector<HTMLInputElement>('[data-package-submit]') || undefined;

		if (!this.input || !this.submit) {
			console.warn(
				'The BusinessPackages component didn’t find all needed elements.'
			);
			return;
		}

		const elements = el.querySelectorAll<HTMLElement>('[data-package-range]');
		this.elements = elements ? Array.from(elements) : [];

		this.input.addEventListener('keyup', this.handleKeyUp);
		this.input.addEventListener('change', this.handleChange);

		const clickable = el.querySelectorAll('[data-package-replace-input]');
		clickable.forEach((clickEl) =>
			clickEl.addEventListener('click', () => {
				const amount = clickEl.getAttribute('data-package-replace-input');
				if (!amount || !this.input) return;

				this.input.value = amount;
				const keyUp = new Event('keyup');
				this.input.dispatchEvent(keyUp);
				this.handleKeyUp();
			})
		);

		this.handleKeyUp();
	}

	handleKeyUp = () => {
		const num = Number(this.input?.value);
		if (!isNaN(num)) {
			this.setClassNames(num);
		}

		this.updateRadioButtons();

		if (this.input?.validity.valid) {
			this.submit?.removeAttribute('disabled');
		} else {
			this.submit?.setAttribute('disabled', 'disabled');
		}
	};

	handleChange = () => {
		this.showValidation();
	};

	getInsideRangeClassName = (el: HTMLElement) => {
		return el.getAttribute('data-package-in-range-class') || 'highlight';
	};

	getOutsideRangeClassName = (el: HTMLElement) => {
		return (
			el.getAttribute('data-package-outside-range-class') || 'dimmed-exclude'
		);
	};

	setClassNames = (num: number) => {
		const anyHighlighted =
			this.elements?.some((el) => this.shouldHighlight(num, el)) || false;

		if (!anyHighlighted) {
			this.elements?.forEach((el) => {
				el.classList.remove(this.getInsideRangeClassName(el));
				el.classList.remove(this.getOutsideRangeClassName(el));
			});
		} else {
			this.showValidation();
			this.elements?.forEach((el) => {
				const active = this.shouldHighlight(num, el);
				if (active) {
					el.classList.add(this.getInsideRangeClassName(el));
					el.classList.remove(this.getOutsideRangeClassName(el));
				} else {
					el.classList.add(this.getOutsideRangeClassName(el));
					el.classList.remove(this.getInsideRangeClassName(el));
				}
			});
		}
	};

	shouldHighlight = (num: number, el: HTMLElement) => {
		const attr = el.getAttribute('data-package-range');
		if (!attr) return false;

		const [from, to] = attr
			.split(',')
			.map((n) => Number(n))
			.filter((n) => !isNaN(n));

		const inLowerBounds = num >= from;
		const inHigherBounds = (to && num <= to) || to === 0;

		return inLowerBounds && inHigherBounds;
	};

	showValidation = () => {
		if (this.isShowingValidation) return;
		this.input?.classList.add('u-show-validation');
	};

	updateRadioButtons = () => {
		const radios = Array.from(
			this.el.querySelectorAll<HTMLInputElement>('[data-package-replace-input]')
		);
		const current = radios
			.reverse()
			.find(
				(radio) =>
					(this.input?.value || 0) >=
					Number(radio.getAttribute('data-package-replace-input') || Infinity)
			);
		if (current) {
			current.checked = true;
			const changeEvent = new Event('change');
			current.dispatchEvent(changeEvent);
		}
	};
}
