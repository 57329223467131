const hasDatePicker = (function () {
	var input = document.createElement('input');
	var value = 'a';
	input.setAttribute('type', 'date');
	input.setAttribute('value', value);
	return input.value !== value;
})();

const init = async () => {
	const datepicker = (await import('js-datepicker')).default;

	const dateSelector = 'input[type="date"]';

	const zeroPad = (no, len) => `${no}`.padStart(len, '0');
	const formatDate = (date) => {
		return [
			zeroPad(date.getFullYear(), 4),
			zeroPad(date.getMonth() + 1, 2),
			zeroPad(date.getDate(), 2),
		].join('-');
	};

	/**
	 * js-datepicker will not work in date inputs, so they need to
	 * be changed to text.
	 */
	const dateInputs = document.querySelectorAll(dateSelector);
	[...dateInputs].forEach((input) => {
		input.setAttribute('type', 'text');

		// Validate input pattern
		input.setAttribute('pattern', '\\d{4}-?\\d{2}-?\\d{2}');
		if (!input.getAttribute('data-pattern-mismatch')) {
			input.setAttribute(
				'data-pattern-mismatch',
				'Datum måste skrivas ÅÅÅÅ-MM-DD'
			);
		}

		input.setAttribute('autocomplete', 'nope');

		const days = ['Sön', 'Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör'];
		const months = [
			'Januari',
			'Februari',
			'Mars',
			'April',
			'Maj',
			'Juni',
			'Juli',
			'Augusti',
			'September',
			'Oktober',
			'November',
			'December',
		];

		const opts = {};

		const minDate = input.getAttribute('min');
		if (minDate) {
			opts.minDate = new Date(minDate);
		}

		const maxDate = input.getAttribute('max');
		if (maxDate) {
			opts.maxDate = new Date(maxDate);
		}

		datepicker(input, {
			startDay: 1,
			formatter: (input, date) => {
				input.value = formatDate(date);
			},
			customDays: days,
			customMonths: months,
			disableYearOverlay: true,
			onSelect: () => {
				// change event to trigger validation
				const event = document.createEvent('HTMLEvents');
				event.initEvent('change', false, true);
				input.dispatchEvent(event);
			},
			...opts,
		});
	});
};

if (!hasDatePicker) {
	init();
}
